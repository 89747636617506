import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TenantsCreateRequest } from "src/app/api.services/models/models";

@Component({
    selector: 'add.tenant.dialog',
    templateUrl: 'add.tenant.dialog.html',
})
export class AddTenantDialog {

    public isActive: false;
    constructor(
        public dialogRef: MatDialogRef<AddTenantDialog>,
        @Inject(MAT_DIALOG_DATA) public data: TenantsCreateRequest) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    change(): void {
        !this.isActive;
    }
}