import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'complete-check.dialog',
    templateUrl: 'complete-check.dialog.html',
})
export class CompleteCheckDialog {

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: { id: string, done: boolean }) { }

    onNoClick(): void {
       this.dialogRef.close();
    }
}