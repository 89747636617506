import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { AuthenticationLeaderInFocusService } from './authenticationLeaderInFocus.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private authenticationLeaderInFocusService: AuthenticationLeaderInFocusService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header
        let userToken = this.authenticationService.getAuth();
        let lederInFocuseToken = this.authenticationLeaderInFocusService.getAuth();
        if (userToken) {
            const clonedRequest = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${userToken.token}`
                }
            });
            return next.handle(clonedRequest);
        }
        if (lederInFocuseToken) {
            const clonedRequest = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${lederInFocuseToken.token}`
                }
            });
            return next.handle(clonedRequest);
        }
        // Pass the cloned request instead of the original request to the next handle
        return next.handle(req);
    }
}