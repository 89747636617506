import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RecipientGroupsGetByCompanyIdResponseItem, QuestionnaireRecipientsAddRecipientsRequest, QuestionnaireRecipientsAddRecipientsRequestItem } from "../api.services/models/models";
import { RecipientGroupsApiService } from "../api.services/recipient.groups.api.service";
import { ToastrService } from "ngx-toastr";
import { Guid } from "guid-typescript";
import { DatePipe } from '@angular/common';


@Component({
    selector: 'add.recipient.dialog',
    templateUrl: 'add.recipient.dialog.html',
})
export class AddQuestionnareRecipientDialog implements OnInit {

    public recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[];
    public companyId: string;
    public dueDate: Date;
    constructor(
        private recipientGroupApi: RecipientGroupsApiService,
        public dialogRef: MatDialogRef<any>,
        private toster: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: QuestionnaireRecipientsAddRecipientsRequest) { }


    public async ngOnInit() {
        this.companyId = this.data.recipients[0].companyId;
        this.recipientGroups = await this.recipientGroupApi.getRecipientGroupsByCompanyId(this.companyId);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSaveClick(): void {
        const datepipe: DatePipe = new DatePipe('en-US');
        for (let index = 0; index < this.data.recipients.length; index++) {
            if (this.data.recipients[index].email != "" && this.data.recipients[index].firstName != "" &&
                this.data.recipients[index].lastName != "" && this.data.recipients[index].recipientGroupId != "" && this.dueDate != null) {
                this.data.recipients[index].dueDate = datepipe.transform(this.dueDate, 'dd-MMM-yyyy HH:mm:ss');
            }
            else {
                this.toster.info("Set all info for the recipients.");
                return;
            }
        }
        this.dialogRef.close(this.data);
    }

    public addRecipient() {
        const datepipe: DatePipe = new DatePipe('en-US');
        this.data.recipients.push({
            companyId: this.companyId,
            firstName: "",
            lastName: "",
            email: "",
            recipientGroupId: "",
            dueDate: datepipe.transform(this.dueDate, 'dd-MMM-yyyy HH:mm:ss'),
            recipinetId: Guid.create().toString()
        });
    }

    public onRemove(recipientIndex: number) {
        this.data.recipients.splice(recipientIndex, 1);
    }
}
