import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem, QuestionnaireRecipientsGetRecipientGroupLimitResponseItem } from '../api.services/models/models';
import { ActivatedRoute } from '@angular/router';
import { QuestionnairesRecipientsApiService } from '../api.services/questionnaire.recipients.api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { ResendEmailDialog } from './resend-email.dialog';
import { ToastrService } from 'ngx-toastr';
import { ResendEmailAllDialog } from './resend-email-all.dialog';
import { DeleteQuestionnareRecipientDialog } from './delete.recipients.dialog';
import { EditQuestionnareRecipientDialog } from './edit.recipient.dialog';
import { AddQuestionnareRecipientDialog } from './add.recipient.dialog';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'questionnaire-recipients',
    templateUrl: './questionnaire-recipients.component.html'
})

export class QuestionnaireRecipientsComponent implements OnInit {
    public companyId: string;
    public companyName: string;
    public questionnaireId: string;
    public questionnaireName: string;
    public hideResendAll: boolean;
    public isLeaderInFocus: boolean;
    public recipents: QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem[] = [];
    public recipientLimit: QuestionnaireRecipientsGetRecipientGroupLimitResponseItem[];
    public recipientLimitWithOutZero: QuestionnaireRecipientsGetRecipientGroupLimitResponseItem[];
    public groupedRecipient = new Map();
    public displayedColumns: string[] = [
        'status',
        'fullName',
        'email',
        'recipientGroup',
        'dueDate',
        'actions',
    ];
    public dataSource = new MatTableDataSource<QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem>(this.recipents);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatButton) button: MatButton;

    constructor(
        private questionnairesRecipientsApiService: QuestionnairesRecipientsApiService,
        private route: ActivatedRoute,
        private resendEmailDialog: MatDialog,
        private deleteDialog: MatDialog,
        private resendEmailAllDialog: MatDialog,
        private editDialog: MatDialog,
        private addDialog: MatDialog,
        private toastr: ToastrService
    ) { }

    public async ngOnInit() {
        this.companyId = this.route.snapshot.paramMap.get('companyId');
        this.companyName = this.route.snapshot.paramMap.get('companyName');
        this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
        this.questionnaireName = this.route.snapshot.paramMap.get("questionnaireName");
        this.isLeaderInFocus = this.companyName == null;

        this.recipientLimit = await this.questionnairesRecipientsApiService.getRecipientsLimitByQuestionnaireId(this.questionnaireId);
        this.recipientLimitWithOutZero = this.recipientLimit.filter(x => x.minRecipients>0);
        await this.getAllQuestionnaireRecipientsByQuestionnaireId();
    }
     public groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            if (!map.has(key)) {
                map.set(key, [item]);
            } else {
                map.get(key).push(item);
            }
        });
        return map;
    }
    private async getAllQuestionnaireRecipientsByQuestionnaireId() {
        this.recipents = await this.questionnairesRecipientsApiService.getAllQuestionnaireRecipentsByQuestionnaireId(this.questionnaireId);
        this.groupedRecipient = this.groupBy(this.recipents, pet => pet.recipientGroupId );
        this.dataSource = new MatTableDataSource<QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem>(this.recipents);
        this.dataSource.paginator = this.paginator;
        this.hideResendAll = this.recipents.filter(x => !x.isAnswerd).length > 0 ? true : false;
    }

    public openDialogResendEmail(element): void {
        const elemRect = (event.target as HTMLElement).getBoundingClientRect()
        const dialogRef = this.resendEmailDialog.open(ResendEmailDialog, {
            width: '300px',
            data: { recipientId: element.id, dueDate: element.dueDate },
            autoFocus: false,
            position: {
                top: elemRect.bottom + 10 + "px",
                left: elemRect.right - 200 + "px"
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var date = new Date();
                date.setHours(0, 0, 0, 0);
                var dueDate = new Date(result.dueDate);
                if (dueDate >= date) {
                    const datepipe: DatePipe = new DatePipe('en-US');
                    let dueDate = datepipe.transform(result.dueDate, 'dd-MMM-yyyy HH:mm:ss');
                    var apiResalt = await this.questionnairesRecipientsApiService.resendEmailByRecipientId(result.recipientId, dueDate);
                    if (!apiResalt.isSuccess) { this.toastr.error("Email message was not send.") }
                    else {
                        this.toastr.success("Email was successfully send.");
                        await this.getAllQuestionnaireRecipientsByQuestionnaireId();
                    }
                }
                else {
                    this.toastr.info("Set date greater than yesterday.")
                }
            }
        });
    }

    public openDialogResendEmailToAll(): void {
        const dialogRef = this.resendEmailAllDialog.open(ResendEmailAllDialog, {
            width: '400px',
            data: { questionnaireId: this.questionnaireId },
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var date = new Date();
                date.setHours(0, 0, 0, 0);
                var dueDate = new Date(result.dueDate);
                if (dueDate >= date) {
                    const datepipe: DatePipe = new DatePipe('en-US');
                    let dueDate = datepipe.transform(result.dueDate, 'dd-MMM-yyyy HH:mm:ss');
                    var apiResalt = await this.questionnairesRecipientsApiService.resendEmailForAllRecipients(result.questionnaireId, dueDate);
                    if (!apiResalt.isSuccess) { this.toastr.error("Email message was not send.") }
                    else {
                        this.toastr.success("Email was successfully send.");
                        await this.getAllQuestionnaireRecipientsByQuestionnaireId();
                    }
                }
                else {
                    this.toastr.info("Set date greater than yesterday.")
                }
            }
        });
    }


    openDialogDeleteRecipent(element): void {
        var findRecipientLimit = this.recipientLimit.find(x => x.recipientGroupId == element.recipientGroupId);
        var recipientGroupBYGroup = this.groupedRecipient.get(element.recipientGroupId);
        const dialogRef = this.deleteDialog.open(DeleteQuestionnareRecipientDialog, {
            width: '400px',
            data: { id: element.id, 
                name: element.email,
                groupName: recipientGroupBYGroup.length > 0 ? recipientGroupBYGroup[0].recipientGroup : "",
                isLimit: findRecipientLimit.minRecipients < recipientGroupBYGroup.length,
                minNum: findRecipientLimit.minRecipients
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiResalt = await this.questionnairesRecipientsApiService.deleteQuestionnaireRecipent(result.id);
                if (!apiResalt.isSuccess) { this.toastr.error("Recipient is not deleted.") }
                else {
                    this.toastr.success("Recipient was successfully deleted.");
                    await this.getAllQuestionnaireRecipientsByQuestionnaireId();
                }
            }
        });
    }

    openDialogEditRecipent(element: QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem): void {
        const dialogRef = this.editDialog.open(EditQuestionnareRecipientDialog, {
            width: '900px',
            data: {
                companyId: this.companyId,
                questionnaireId: this.questionnaireId,
                recipinetId: element.id,
                firstName: element.fullName != "" ? element.fullName.split(' ')[0] : "",
                lastName: element.fullName != "" ? element.fullName.split(' ')[1] : "",
                email: element.email,
                recipientGroupId: element.recipientGroupId,
                dueDate: element.dueDate
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                const datepipe: DatePipe = new DatePipe('en-US');
                let dueDate = datepipe.transform(result.dueDate, 'dd-MMM-yyyy HH:mm:ss')
                var apiResalt = await this.questionnairesRecipientsApiService.editQuestionnaireRecipent(result, dueDate);
                if (!apiResalt.isSuccess) { this.toastr.error("The recipient is not edited.") }
                else {
                    this.toastr.success("The recipient was successfully edited.");
                    await this.getAllQuestionnaireRecipientsByQuestionnaireId();
                }
            }
        });
    }

    openDialogAddRecipent(): void {
        const dialogRef = this.addDialog.open(AddQuestionnareRecipientDialog, {
            width: '900px',
            data: {
                questionnaireId: this.questionnaireId,
                recipients: [{
                    companyId: this.companyId,
                    recipinetId: Guid.create().toString(),
                    firstName: "",
                    lastName: "",
                    email: "",
                    recipientGroupId: "",
                    dueDate: new Date()
                }]
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiResalt = await this.questionnairesRecipientsApiService.addQuestionnaireRecipents(result);
                if (!apiResalt.isSuccess) { this.toastr.error("The recipients are not added.") }
                else {
                    this.toastr.success("The recipient list was successfully updated");
                    await this.getAllQuestionnaireRecipientsByQuestionnaireId();
                }
            }
        });
    }
}

interface PropObject {
    [index: string]: number;
  }