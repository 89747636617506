import {Directive, Self} from '@angular/core';
import { BaseChartComponent, PieGridComponent} from '@swimlane/ngx-charts';

@Directive({
    selector: '[hotfix-zero-margin]'
})
export class NgxPieChartZeroMarginDirective {
    constructor(@Self() pieChart: PieGridComponent) {
        pieChart.margin = [10, 10, 10, 10];
        pieChart.update();
    }
}