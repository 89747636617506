import { ThrowStmt } from "@angular/compiler";
import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import { ControlContainer, FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ToastrService } from "ngx-toastr";
import { TemplateQuestionsGetByTemplateIdResponseArea } from "src/app/api.services/models/templateQuestionsGetByTemplateIdResponseArea";


@Component({
    selector: 'step-two',
    templateUrl: './step-two.component.html'
})

export class StepTwoComponent implements OnInit {
    @Input() stepper: MatStepper;

    public _areas: TemplateQuestionsGetByTemplateIdResponseArea[] | null = null;
    @Input() set areas(value: TemplateQuestionsGetByTemplateIdResponseArea[] | null) {
        if (value != null) {
            this.populateForm();
            for (const area of value) {
                this.addAreaInit(area);
            }
        }
        this._areas = value;
    }
    get areas(): TemplateQuestionsGetByTemplateIdResponseArea[] | null {
        return this._areas;
    }

    @Output() nextStep = new EventEmitter();
    @Output() backStep = new EventEmitter();

    public form: FormGroup;
    public questionTypes: string[];
    public isDataTwoInitialized: boolean = false;
    public step: number = 0;

    constructor(public controlContainer: ControlContainer,
        private formBuilder: FormBuilder,
        private toster: ToastrService) { }

    public ngOnInit(): void {
        this.questionTypes = ['String', 'Bool', 'Rating'];
        this.populateForm();
        this.isDataTwoInitialized = true;
    }

    public panelExpansion(index) {
        this.step = index;
    }

    public populateForm() {
        this.form = this.formBuilder.group({
            ratingValue: [10],
            areas: new FormArray([])
        })
    }

    public addArea() {
        let control = <FormArray>this.form.controls.areas;
        this.step = control.length;
        control.push(this.initArea());
    }

    public onRemoveArea(index) {
        let control = <FormArray>this.form.controls.areas;
        this.step = control.length - 1;
        control.removeAt(index)
    }

    public addNewQuestion(control) {
        control.push(this.initQuestion());
    }

    public onRemoveQuestion(control, index) {
        control.removeAt(index)
    }

    public initArea() {
        return this.formBuilder.group({
            title: [, Validators.required],
            isInNegativeConotation: [false],
            questions: new FormArray([
                this.initQuestion()
            ])
        })
    }

    public initQuestion() {
        return this.formBuilder.group({
            title: [, Validators.required],
            description: [],
            questionType: [, Validators.required],
            ratingValue: [],
            israting: [false]
        });
    }
    public addAreaInit(area) {
        let control = <FormArray>this.form.controls.areas;
        control.push(this.initAreaInit(area));
    }


    private initAreaInit(area) {
        return this.formBuilder.group({
            title: [area.title, Validators.required],
            isInNegativeConotation: [area.isInNegativeConotation],
            questions: this.initQuestions(area.questions)
        })
    }

    private initQuestions(questions) {
        var questionArray = new FormArray([]);
        questions.forEach(element => {
            var rating = element.questionType == 'Rating' ? true : false;
            var formGroup = this.formBuilder.group({
                title: [element.title, Validators.required],
                description: [element.description],
                questionType: [element.questionType, Validators.required],
                ratingValue: [element.ratingValue],
                israting: [rating]
            });
            questionArray.push(formGroup);
        });
        return questionArray;
    }


    public onNext() {
        if (!this.form.invalid && this.form.value.areas.length > 0) {
            this.stepper.next();
            this.nextStep.emit();
        }
        else {
            this.toster.info("You need to add Area and Questions");
            return;
        }
    }

    public onPrevious() {
        if (!this.form.invalid) {
            this.stepper.previous();
            this.backStep.emit();
        }
    }
}