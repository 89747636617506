import { Component, OnInit } from "@angular/core";
import { Route } from "@angular/compiler/src/core";
@Component({
  selector: 'message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit {
  public message: string;
  public ngOnInit(): void {
    
    this.message = history.state.message;
    if(this.message == undefined || this.message == null){
        this.message ="Message"
    }
  }
}
