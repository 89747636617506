import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'indexInfo.dialog',
    templateUrl: 'indexInfo.dialog.html',
})
export class IndexInfoDialog {

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
       this.dialogRef.close();
    }
}