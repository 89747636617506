import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersEditRequest } from "src/app/api.services/models/models";

@Component({
    selector: 'edit.user.dialog',
    templateUrl: 'edit.user.dialog.html',
})
export class EditUserDialog{


    constructor(
        public dialogRef: MatDialogRef<EditUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data: UsersEditRequest) { }
    
    onNoClick(): void {
        this.dialogRef.close();
    }
}