import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TemplatesApiService } from 'src/app/api.services/templates.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplatesCreateRequest, TemplatesEditRequest, TemplatesGetByIdResponse } from 'src/app/api.services/models/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html'
})

export class EditTemplateComponent implements OnInit {

  public templateId: string;
  public isDataInitialized: boolean = false;
  public templateName: string;
  public form: FormGroup;
  public formArray: FormArray;
  public questionTypes: string[];
  public rating: boolean = false;
  public area: number;
  public question: number;
  protected isApiRequestInProgress: boolean = false;
  public step: number = 0;
  public template: TemplatesGetByIdResponse;

  constructor(private formBuilder: FormBuilder,
    private templatesApiService: TemplatesApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public toster: ToastrService
  ) { }


  public async ngOnInit() {
    this.templateId = this.activatedRoute.snapshot.paramMap.get('templateId');
    this.template = await this.templatesApiService.getTemplateById(this.templateId);
    this.questionTypes = ['String', 'Bool', 'Rating'];
    this.form = this.formBuilder.group({
      id: [this.template.id],
      title: [this.template.title, Validators.required],
      description: [this.template.description],
      areas: new FormArray([]),
      ratingValue: [10, Validators.required]
    });
    this.addAreaOnInit(this.template.areas);
    this.isDataInitialized = true;
  }

  addArea() {
    let control = <FormArray>this.form.controls.areas;
    control.push(this.initArea());
  }

  onRemoveArea(index) {
    let control = <FormArray>this.form.controls.areas;
    control.removeAt(index)
  }

  addNewQuestion(control) {
    control.push(this.initQuestion());
  }

  onRemoveQuestion(control, index) {
    control.removeAt(index)
  }
  public initArea() {
    return this.formBuilder.group({
      title: [, Validators.required],
      isInNegativeConotation: [false],
      questions: new FormArray([
        this.initQuestion()
      ])
    })
  }

  public initQuestion() {
    return this.formBuilder.group({
      title: [, Validators.required],
      description: [],
      questionType: [, Validators.required],
      ratingValue: [],
      israting: [false]
    });
  }

  public addAreaOnInit(areas) {
    let control = <FormArray>this.form.controls.areas;
    areas.forEach(area => {
      control.push(this.firstInitArea(area));
    });

  }

  public panelExpansion(index) {
    this.step = index;
}

  private firstInitArea(area) {
    return this.formBuilder.group({
      id: [area.id],
      title: [area.title, Validators.required],
      isInNegativeConotation: [area.isInNegativeConotation],
      questions: this.firstInitQuestions(area.questions)
    })
  }

  private firstInitQuestions(questions) {
    var questionArray = new FormArray([]);
    questions.forEach(element => {
      var rating = element.questionType == 'Rating' ? true : false;
      var formGroup = this.formBuilder.group({
        id: [element.id],
        title: [element.title, Validators.required],
        description: [element.description],
        questionType: [element.questionType, Validators.required],
        ratingValue: [element.ratingValue],
        israting: [rating]
      });
      questionArray.push(formGroup);
    });
    return questionArray;
  }



  public async onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    var template = this.form.value as TemplatesEditRequest;
    for (let i = 0; i < template.areas.length; i++) {
      template.areas[i].orderNumber = i + 1;
      for (let j = 0; j < template.areas[i].questions.length; j++) {
        template.areas[i].questions[j].orderNumber = j + 1;
        template.areas[i].questions[j].ratingValue = this.form.get('ratingValue').value;
      }
    }
    if (template.areas.length == 0) {
      this.toster.error("Please add Area.");
      return;
    }
    this.isApiRequestInProgress = true;
    var response = await this.templatesApiService.editTemplates(template);
    this.isApiRequestInProgress = false;

    if (!response.isSuccess) {
      this.toster.error(response.message);
      return;
    }

    this.toster.success("Successfully saved.");
    this.router.navigate([`/templates`]);
  }

}
