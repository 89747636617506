import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateListComponent } from './template-list/template-list.component';
import { CreateTempateComponent } from './create-template/create-tempate.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: TemplateListComponent },
            { path: 'create', component: CreateTempateComponent },
            { path: 'edit/:templateId', component: EditTemplateComponent },
        ],
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class TemplatesRoutingModule { }