import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
    selector: 'step-five',
    templateUrl: './step-five.component.html'
})

export class StepFiveComponent {
    @Output() save = new EventEmitter();
    @Input() set isApiRequestInProgress(value: boolean) {
        this._isApiRequestInProgress = value;
    }
    get isApiRequestInProgress(): boolean {
        return this._isApiRequestInProgress;
    }

    public _isApiRequestInProgress: boolean;
    constructor() { }

    public submit() {
        this.save.emit();
    }
}