import { HttpClient } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";
import { 
    CompaniesGetAllResponse, 
    CompaniesGetAllResponseItem,
    CompaniesGetAllListResponseItem,
    CompaniesGetAllListResponse} from "./models/models";

@Injectable({
    providedIn: 'root'
})
export class CompaniesApiService{
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getCompanies(): Promise<CompaniesGetAllResponseItem[]>{
        let url = `${this.apiUrls.companiesUrl}/all`;
        let apiResult: CompaniesGetAllResponse = await this.http.get<CompaniesGetAllResponse>(url).toPromise();

        return apiResult.items; 
    }
   
    public async createCompany(name: string, email: string) {
        let url = `${this.apiUrls.companiesUrl}/create`;
        let requestBody = { name: name, email: email };
        await this.commandHttp.post(url, requestBody);
    }

    public async editCompany(id: string, name: string, email: string){
        let url = `${this.apiUrls.companiesUrl}/edit`;
        let requestBody = { id: id, name: name, email: email };
        await this.commandHttp.post(url, requestBody);
    }

    public async getCompaniesList(): Promise<CompaniesGetAllListResponseItem[]>{
        let url = `${this.apiUrls.companiesUrl}/all-list`;
        let apiResult: CompaniesGetAllListResponse = await this.http.get<CompaniesGetAllListResponse>(url).toPromise();

        return apiResult.items; 
    }

    public async deleteCompany(id: string) {
        let url = `${this.apiUrls.companiesUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }
}
