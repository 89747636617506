import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { QuestionnaireRecipientsGetAllByQuestionnaireIdResponse, QuestionnaireRecipientsGetRecipientGroupLimitResponse, QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem, QuestionnaireRecipientsEditRecipientRequest, QuestionnaireRecipientsResendEmailByRecipientIdResponse, QuestionnaireRecipientsDeleteRecipientRequest, QuestionnaireRecipientsAddRecipientsRequestItem, QuestionnaireRecipientsAddRecipientsRequest, QuestionnaireRecipientsGetRecipientGroupLimitResponseItem } from "./models/models";
import { ApiResult } from "../shared/models/api-result";

@Injectable({
    providedIn: 'root'
})
export class QuestionnairesRecipientsApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getAllQuestionnaireRecipentsByQuestionnaireId(questionnaireId: string): Promise<QuestionnaireRecipientsGetAllByQuestionnaireIdResponseItem[]> {
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/all-by-questionnaireId`;
        let apiResult: QuestionnaireRecipientsGetAllByQuestionnaireIdResponse = await this.http.get<QuestionnaireRecipientsGetAllByQuestionnaireIdResponse>(url, { params }).toPromise();

        return apiResult.recipients;
    }

    public async editQuestionnaireRecipent(recipent: QuestionnaireRecipientsEditRecipientRequest, dueDate: string)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/edit`;
        var requestBody = {
            companyId: recipent.companyId,
            questionnaireId: recipent.questionnaireId,
            recipinetId: recipent.recipinetId,
            firstName: recipent.firstName,
            lastName: recipent.lastName,
            email: recipent.email,
            recipientGroupId: recipent.recipientGroupId,
            dueDate: dueDate
        };
        return await this.commandHttp.post(url, requestBody);
    }

    public async resendEmailByRecipientId(recipientId: string, dueDate: string) : Promise<ApiResult<QuestionnaireRecipientsResendEmailByRecipientIdResponse>> {
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/resend-email-by-recipientId`;
        var requestBody = {
            recipientId: recipientId,
            dueDate: dueDate
        };
        return await this.commandHttp.post(url, requestBody);
    }

    public async resendEmailForAllRecipients(questionnaireId: string, dueDate: string) : Promise <ApiResult<void>> {
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/resend-email-all`;
        var requestBody = {
            questionnaireId: questionnaireId,
            dueDate: dueDate
        };
        return await this.commandHttp.post(url, requestBody);
    }

    public async deleteQuestionnaireRecipent(id: string)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/delete`;
        var bodyRequest = {id : id };
        return await this.commandHttp.post(url, bodyRequest);
    }

    public async addQuestionnaireRecipents(request: QuestionnaireRecipientsAddRecipientsRequest)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/add`;
        return await this.commandHttp.post(url, request);
    }

    public async getRecipientsLimitByQuestionnaireId(questionnaireId: string): Promise<QuestionnaireRecipientsGetRecipientGroupLimitResponseItem[]> {
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.questionnaireRecipientsUrl}/recipent-limit-by-questionnaireId`;
        let apiResult: QuestionnaireRecipientsGetRecipientGroupLimitResponse = await this.http.get<QuestionnaireRecipientsGetRecipientGroupLimitResponse>(url, { params }).toPromise();

        return apiResult.recipientsList;
    }
    
}