import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TenantsCreateRequest } from "src/app/api.services/models/models";

@Component({
    selector: 'delete.tenant.dialog',
    templateUrl: 'delete.tenant.dialog.html',
})
export class DeleteTenantDialog {

    constructor(
        public dialogRef: MatDialogRef<DeleteTenantDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}