import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationLeaderInFocusService } from '../authenticationLeaderInFocus.service';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';
import { QuestionnairesApiService } from 'src/app/api.services/questionnaires.api.service';

@Component({
  selector: '[leaderInFocuse-login]',
  templateUrl: './leaderInFocuse-login.component.html'
})
export class LeaderInFocuseLogInComponent implements OnInit {
  public loginForm: FormGroup;
  public loginInvalid: boolean;
  public message = new BehaviorSubject<string>(null);
  public returnUrl: string
  public loading: boolean = false;
  public tenant: string = "";
  public email: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authorizationApiService: AuthorizationApiService,
    private authLeaderInFocusService: AuthenticationLeaderInFocusService,
    private questionnairesApiService: QuestionnairesApiService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.tenant = this.activatedRoute.snapshot.paramMap.get('tenantId');
    this.email = localStorage.getItem('email');
    this.loginForm = this.formBuilder.group({
      1: ['', [Validators.required]],
      2: ['', [Validators.required]],
      3: ['', [Validators.required]],
      4: ['', [Validators.required]],
      5: ['', [Validators.required]],
      6: ['', [Validators.required]]
    });
  }

  async onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    let one: string = this.loginForm.get('1').value;
    let two: string = this.loginForm.get('2').value;
    let three: string = this.loginForm.get('3').value;
    let four: string = this.loginForm.get('4').value;
    let five: string = this.loginForm.get('5').value;
    let six: string = this.loginForm.get('6').value;

    let password = `${one}${two}${three}${four}${five}${six}`;

    this.loading = true;
    let result = await this.authorizationApiService.loginLeaderInFocus(this.email, this.tenant, password);
    this.loading = false;

    if (result.isSuccess) {
      localStorage.clear();
      this.authLeaderInFocusService.setAuth(result.data);
      var latest = await this.questionnairesApiService.latest();
      if (latest != undefined || latest != null) {
        this.router.navigate([`/leaderInFocus/${this.tenant}/insight/${latest}`]);
      }
      else {
        this.router.navigate([`/leaderInFocus/${this.tenant}/overview`]);
      }
    }
    else {
      this.toastrService.error(result.message)
    }

  }
  onPaste(e) {
    // if the target is a text input
    if (e.target.type === "text") {
      var data = e.clipboardData.getData('Text');
      // split clipboard text into single characters
      data = data.split('');
      // find all other text inputs
      [].forEach.call(document.querySelectorAll("input[type=text]"), (node, index) => {
        // And set input value to the relative character
        this.loginForm.get(`${node.id}`).setValue(data[index]);
      });
    }
  }


}