import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UsersGetAllResponseItem } from 'src/app/api.services/models/usersGetAllResponseItem';
import { UsersApiService } from 'src/app/api.services/users.api.service';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';
import { ToastrService } from 'ngx-toastr';
import { UsersDeleteRequest, UsersEditRequest } from 'src/app/api.services/models/models';
import { AddAdminDialog } from '../add-admin/add.admin.dialog';
import { DeleteAdminDialog } from '../delete-admin/delete.admin.dialog';
import { EditAdminDialog } from '../edit-admin/edit.admin.dialog';

@Component({
    templateUrl: './admin.user.list.component.html'
})
export class AdminUserListComponent implements OnInit {
    public tenantId: string;
    usersDisplayedColumns: string[] = [
        'email',
        'actions'];

    usersGroups: UsersGetAllResponseItem[] = [];
    usersDataSource = new MatTableDataSource<UsersGetAllResponseItem>(this.usersGroups);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   
    constructor(private usersApiService: UsersApiService, 
         private toastrService : ToastrService,
        private openDialog: MatDialog,
        private route: ActivatedRoute,
        private authorizationApiService: AuthorizationApiService){}

    public async ngOnInit() {
        this.tenantId = this.route.snapshot.paramMap.get('tenantId');
        await this.getUsers();
    }

    private async getUsers(){
        this.usersGroups = await this.usersApiService.getAllAdmins(this.tenantId);
        this.usersDataSource = new MatTableDataSource<UsersGetAllResponseItem>(this.usersGroups);
        this.usersDataSource.paginator = this.paginator;
    }

    openDialogAddUser(): void {
        const dialogRef = this.openDialog.open(AddAdminDialog, {
            width: '600px',
            data: {   
                email: '',
                role: 'Admin',
                tenantId: this.tenantId
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResult = await this.usersApiService.createUser(result);
                if(apiRequestResult.isSuccess){
                    this.toastrService.success("User was successfully saved.");
                    this.getUsers();
                }
                else {
                    this.toastrService.error("User was not saved.");
                }
            }
        });   
    }

  

    openDialogEditUser(element: UsersEditRequest): void {
        const dialogRef = this.openDialog.open(EditAdminDialog, {
            width: '600px',
            data: {   
                id: element.id,
                email: element.email,
                role: element.role,
                tenantId: this.tenantId
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResult = await this.usersApiService.editUser(result);
                if(apiRequestResult.isSuccess){
                    this.toastrService.success("User was successfully edited.");
                    this.getUsers();
                }
                else {
                    this.toastrService.error("User was not edited.");
                }
            }
        });  
    }

    openDialogDeleteUser(element: UsersDeleteRequest): void {
        const dialogRef = this.openDialog.open(DeleteAdminDialog, {
            width: '600px',
            data: {   
                id: element.id,
                email: element.email,
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResult = await this.usersApiService.deleteUser(result);
                if(apiRequestResult.isSuccess){
                    this.toastrService.success("User was successfully deleted.");
                    this.getUsers();
                }
                else {
                    this.toastrService.error("User was not deleted.");
                }
            }
        });   
    }

    async resetPassword(element: UsersGetAllResponseItem): Promise<void> {
        var apiRequestResult = await this.authorizationApiService.setPasswordRequest(element.id);  
        if(apiRequestResult.isSuccess){
            this.toastrService.success("Reset password request was successfully sent.");
            this.getUsers();
        }
        else {
            this.toastrService.error("Reset password request was not sent.");
        }
    }
}