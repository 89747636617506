import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';;
import { AuthenticationLeaderInFocusService } from '../authenticationLeaderInFocus.service';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';

const jwtHelper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class LeaderInFocuseGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationLeaderInFocusService,
    private toastr: ToastrService,
    private authorizationApiService: AuthorizationApiService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    //console.log("AuthGuard - CanActivate - " + route.url);

    let isLoggedIn: boolean = this.authenticationService.isLoggedIn();

    // If the user is not logged in, redirect to the login page
    if (!isLoggedIn) {
      //console.log("AuthGuard: User has no token, so redirecting to login page.");
      this.router.navigate(['/login']);
      return false;
    }

    // Validate token
    return this.authorizationApiService.validateAccessToken().pipe(flatMap(isTokenValid => {
      if (isTokenValid) {
        return of(true);
      }

      // If the Access token is not valid, try to refresh it
      return this.refreshToken(state.url);
    }));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.canActivate(route, state);
  }

  private refreshToken(returnUrl: string): Observable<boolean> {
    return this.authorizationApiService.validateAccessToken().pipe(map(isTokenValid => {
        if (!isTokenValid) {
            this.router.navigate(['/login']);
        }
        return isTokenValid;
    }));
  }
}