import { OnInit, Component, Input } from "@angular/core";
import { ControlContainer, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';
import { Guid } from "guid-typescript";
import { ToastrService } from "ngx-toastr";
import { RecipientGroupsGetByCompanyIdResponseItem } from "src/app/api.services/models/models";

@Component({
  selector: 'step-four',
  templateUrl: './step-four.component.html'
})

export class StepFourComponent implements OnInit {
  @Input() stepper: MatStepper;
  @Input() set recipientGroups(value: RecipientGroupsGetByCompanyIdResponseItem[] | null) {
    if(value != null){
      this.addRecipientLimitFormGroup(value);
    }
    this._recipientGroups = value;
  }
  get recipientGroups(): RecipientGroupsGetByCompanyIdResponseItem[] {
    return this._recipientGroups;
  }
  public _recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[];
  public form: FormGroup;
  public isDataInitialized: boolean = false;
  

  constructor(public controlContainer: ControlContainer,
    private formBuilder: FormBuilder,
    private toster: ToastrService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.populateForm();
    this.isDataInitialized = true;
  }

  public populateForm(){
      this.form = this.formBuilder.group({
        recipientsLimit: new FormArray([])
      })
  }
  private addRecipientLimitFormGroup(recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[]) {
    let control = <FormArray>this.form.controls.recipientsLimit;
    recipientGroups.forEach(recipientGroup => {
      var guidValue = Guid.create();
      let formGroup: FormGroup = this.formBuilder.group({
        id: [guidValue.toString()],
        name: [recipientGroup.name],
        minRecipients: [recipientGroup.minRecipients, [Validators.required]],
        recipientGroupId: [recipientGroup.id]
      });
      control.push(formGroup);
    });
   
  }

  public onNext() {
    if(!this.form.invalid){
      this.stepper.next();
    }
    else{
      this.toster.info("You need to add recipient group limitation");
    }
  }

  public onPrevious() {
    if(!this.form.touched){
      this.stepper.previous();
    }
    else{
      if(!this.form.invalid ){
        this.stepper.previous();
      }
      else{
        this.toster.info("You need to add recipient group limitation");
      }
    }
  }
}
