import { AngularMaterialModule } from './modules/angular-material.module';
import { NgModule } from '@angular/core';
import { QuestionTypePipe } from './pipe/question-type.pipe';

@NgModule({
    imports: [
      AngularMaterialModule

    ],
    declarations: [
      QuestionTypePipe     
    ],
    exports:[
      QuestionTypePipe
    ]
  })
export class SharedModule { }