import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationLeaderInFocusService } from 'src/app/authorization/authenticationLeaderInFocus.service';

@Component({
    selector:'[leaderInFocus-layout]',
    templateUrl: './leaderInFocus-layout.component.html',
})
export class LeaderInFocusLayoutComponent {
    role:string;
    sidenavWidth = 4;
    showToggle = false;
    checked = true;
    show = true;
    sideNav = true;
    sidenavWidthButton = 15;
    fix = true;
    mode = 'side';
    isDisabled = false;
    public tenant: string;

    constructor(private router : Router,
      private authService: AuthenticationLeaderInFocusService) { 
      this.slideToggle();
    }
  
    async ngOnInit() {
      this.role = this.authService.getRole();
      this.tenant = this.authService.getTenant();
    }
    
    slideToggle(){
      if(this.checked == true){
        this.sidenavWidth = 12;
        this.sidenavWidthButton = 210;
        this.showToggle = true;
        this.checked = false;
        this.show = true;
        this.fix = true; 
      }
      else{        
        this.sidenavWidth = 4;
        this.sidenavWidthButton = 56;
         this.showToggle = false;
         this.checked = true;
         this.fix = false; 
          this.show = false;
      }
    }

    toggle(){
      this.sidenavWidth = 12;
      if(this.sideNav == true){
        this.sideNav = false;
        this.sidenavWidthButton = 0;
       }
       else{
        this.sideNav = true;
        this.sidenavWidthButton = 0;
       }
    }

    public logOff(){
        this.authService.logout();
        this.router.navigate([`/leaderInFocus/${this.tenant}`]);
    }
}

