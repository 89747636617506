import { OnInit, Component, Input } from "@angular/core";
import { ControlContainer, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Recipient, LeaderInFocus } from "../add.questionnaire";
import { RecipientGroupsGetByCompanyIdResponseItem } from "src/app/api.services/models/models";
import { Guid } from "guid-typescript";
import { MatStepper } from "@angular/material/stepper";
import { ToastrService } from "ngx-toastr";
import { QuestionnairesApiService } from "src/app/api.services/questionnaires.api.service";

@Component({
  selector: 'step-three',
  templateUrl: './step-three.component.html'
})

export class StepThreeComponent implements OnInit {
  @Input() stepper: MatStepper;
  @Input() set recipientGroups(value: RecipientGroupsGetByCompanyIdResponseItem[]) {
    if (value != null) {
      value.forEach(val => {
        if (val.isLeader) {
          this.leaderInFocusGroups.push(val);
        }
        else
          this.groupsWithoutLeader.push(val);
      })
    }
    this._recipientGroups = value;
  }
  get recipientGroups(): RecipientGroupsGetByCompanyIdResponseItem[] {
    return this._recipientGroups;
  }
  public _recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[];

  public leaderInFocusGroups: RecipientGroupsGetByCompanyIdResponseItem[] = [];
  public groupsWithoutLeader: RecipientGroupsGetByCompanyIdResponseItem[] = [];
  @Input() set companyId(value: string) {
    this._companyId = value;
  }
  get companyId(): string {
    return this._companyId;
  }
  public _companyId: string;
  public form: FormGroup;
  public counter: 0;
  public fileToUpload: any | null = null;
  public groupRecipientCount: [number, number];
  public step: number = 0;
  public isDataInitialized: boolean = false;

  constructor(public controlContainer: ControlContainer,
    private formBuilder: FormBuilder, private toster: ToastrService,
    private questionnairesApiService: QuestionnairesApiService) { }

  public async ngOnInit(): Promise<void> {
    this.populateForm();
    this.isDataInitialized = true;
  }

  public populateForm() {
    this.form = this.formBuilder.group({
      date: [, Validators.required],
      leaderInFocus: new FormArray([])
    });
  }

  public panelExpansion(index) {
    this.step = index;
  }

  public addLeaderInFocus() {
    let control = <FormArray>this.form.controls.leaderInFocus;
    this.step = control.length;
    control.push(this.initLeaderInFocus());
  }

  public addNewRecipient(control) {
    control.push(this.initRecipient());
  }

  public initLeaderInFocus() {
    return this.formBuilder.group({
      email: [, [Validators.required, Validators.email]],
      firstName: [, [Validators.required]],
      lastName: [, Validators.required],
      recipientGroupId: [, Validators.required],
      recipients: new FormArray([])
    })
  }

  public initRecipient() {
    return this.formBuilder.group({
      email: [, [Validators.required, Validators.email]],
      firstName: [, [Validators.required]],
      lastName: [, Validators.required],
      recipientGroupId: [, Validators.required],
    });
  }

  public addLeaderInit(leader) {
    let control = <FormArray>this.form.controls.leaderInFocus;
    control.push(this.initLeaderInFocusInit(leader));
  }

  private initLeaderInFocusInit(leader) {
    var guidValue = Guid.create();
    return this.formBuilder.group({
      id: [guidValue.toString()],
      email: [leader.email, [Validators.required, Validators.email]],
      firstName: [leader.firstName, [Validators.required]],
      lastName: [leader.lastName, Validators.required],
      recipientGroupId: [leader.groupId, Validators.required],
      recipients: this.initRecipients(leader.recipients)
    })
  }

  private initRecipients(recipients: Recipient[]) {
    var recipientsArray = new FormArray([]);
    if (recipients != undefined) {
      recipients.forEach(recipient => {
        var guidValue = Guid.create();
        var formGroup = this.formBuilder.group({
          id: [guidValue.toString()],
          email: [recipient.email, [Validators.required, Validators.email]],
          firstName: [recipient.firstName, [Validators.required]],
          lastName: [recipient.lastName, Validators.required],
          recipientGroupId: [recipient.groupId, Validators.required]
        });
        recipientsArray.push(formGroup);
      });
    }
    return recipientsArray;
  }

  public onRemoveLeader(recipientIndex: number) {
    let control = <FormArray>this.form.controls.leaderInFocus;
    control.removeAt(recipientIndex);
    this.form.markAsDirty();
  }

  public onRemoveRecipient(control, index) {
    control.removeAt(index)
  }

  async onFileSelected(files: any) {
    if (files) {
      this.fileToUpload = files.files.item(0);
      if (this.fileToUpload) {
        await this.prepareFilesList(this.fileToUpload);
        if (this.fileToUpload) {
          var result = await this.questionnairesApiService.importExcel(this.fileToUpload.fileAsBase64, this.companyId);

          if (result.isSuccess) {
            if (result.data.isSuccess) {
              if (result.data.items != undefined) {
                result.data.items.forEach(item => {
                  var leaderInFocus = new LeaderInFocus();
                  leaderInFocus.email = item.email;
                  leaderInFocus.firstName = item.firstName;
                  leaderInFocus.lastName = item.lastName;
                  leaderInFocus.groupId = item.recipientGroupId;
                  leaderInFocus.recipients = [];
                  if (item.recipients != undefined) {
                    item.recipients.forEach(rec => {
                      var recipient = new Recipient();
                      recipient.email = rec.email;
                      recipient.firstName = rec.firstName;
                      recipient.lastName = rec.lastName;
                      recipient.groupId = rec.recipientGroupId;
                      leaderInFocus.recipients.push(recipient);
                    })
                  }
                  this.addLeaderInit(leaderInFocus);
                });
                this.toster.success(result.data.message);
              }
            }
            else {
              this.toster.error(result.data.message);
            }
          }
          else {
            this.toster.error("Import was not successful");
          }
        }
      }
    }
  }

  async prepareFilesList(file: any): Promise<any> {
    if (this.checkFile(file)) {
      file.progress = true;
      var baseAs64 = await this.updateFileAsBase64(file);
      file.fileAsBase64 = baseAs64.substr(baseAs64.indexOf(',') + 1);
      return file;
    }
    else {
      this.toster.error(`File size or file type is not permitted ${file.name}.`);
    }

  }
  checkFile(file: any): boolean {
    var fileExt: string = file.name.split('.')[1];
    if (file.size > 0 || file.size < 25000000) {
      return true;
    }
    return false;
  }
  async updateFileAsBase64(file: any) {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        var data = event.target.result?.toString();
        resolve(data);
      };
      reader.readAsDataURL(file);
    });
  }

  public async downloadTemplate(){
    var template = await this.questionnairesApiService.downloadTemplate();
    let url: string = window.URL.createObjectURL(template);
    let aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.setAttribute('style', 'display: none');
    aTag.href = url;
    aTag.download = "Template.xlsx"
    aTag.click();
    window.URL.revokeObjectURL(url);
    aTag.remove();
  }
  public onNext() {
    if (!this.form.invalid) {
      this.stepper.next();
    }
    else if (this.form.get('leaderInFocus').value.length == 0) {
      this.toster.info("You need to add Leader In Focus");
    }
    else if (this.form.get('date').hasError('required')) {
      this.toster.info("You need to set Due Date");
    }
  }

  public onPrevious() {
    if (!this.form.touched) {
      this.stepper.previous();
    }
    else {
      if (!this.form.invalid) {
        this.stepper.previous();
      }
      else if (this.form.get('leaderInFocus').value.length == 0) {
        this.toster.info("You need to add Leader In Focus");
      }
      else if (this.form.get('date').hasError('required')) {
        this.toster.info("You need to set Due Date");
      }
    }
  }
}


