import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { ApiUrls } from "./api-urls";
import { TemplateQuestionsGetByTemplateIdResponse, 
    TemplatesCreateRequestArea,
    TemplateQuestionsGetByTemplateIdResponseArea} from "./models/models";


@Injectable({
    providedIn: 'root'
})
export class TemplateQuestionsApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getQuestionsByTemplateId(templateId: string): Promise<TemplateQuestionsGetByTemplateIdResponseArea[]> {
        let params = new HttpParams({ fromObject: { templateId: templateId } });
        let url = `${this.apiUrls.templateQuestionsUrl}/all-by-templateId-list`;
        let apiResult: TemplateQuestionsGetByTemplateIdResponse = await this.http.get<TemplateQuestionsGetByTemplateIdResponse>(url, {params: params}).toPromise();
        return apiResult.areas;
    }
}