import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'informational.dialog',
    templateUrl: 'informational.dialog.html',
})
export class InformationalDialog {

    constructor(
        public dialogRef: MatDialogRef<InformationalDialog>,
        @Inject(MAT_DIALOG_DATA) public data: boolean) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}