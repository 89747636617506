import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { pipe } from "rxjs";
import { GDPRApiService } from "src/app/api.services/gdpr.api.service";
import { AuthenticationService } from "src/app/authorization/authentication.service";
import { AuthenticationLeaderInFocusService } from "src/app/authorization/authenticationLeaderInFocus.service";

@Component({
  selector: 'consent',
  templateUrl: './consent.component.html'
})
export class ConsentComponent implements OnInit {

  public email: string | null = null;
  public userConsent = false;
  public researchConsent = false;
  public marketingConsent = false;
  public returnUrl: string = "";
  public loading: boolean = false;
  public ready: boolean = false;

  constructor(
    private gdprApiService: GDPRApiService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authenticationLeaderInFocusService: AuthenticationLeaderInFocusService
  ) { }

  async ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';

    let isLoggedIn: boolean = this.authenticationService.isLoggedIn() || this.authenticationLeaderInFocusService.isLoggedIn();

    // If the user is logged in, redirect to the check GDPR by email
    if (isLoggedIn) {
      this.email = this.authenticationService.isLoggedIn() ? this.authenticationService.getAuth().email : this.authenticationLeaderInFocusService.getAuth().email;
      this.ready = true;

    }
    else {
      this.email = localStorage.getItem('email');
      if (this.email == null) {
        var token = this.returnUrl.replace('/surveys/', '');
        this.gdprApiService.checkByToken(token).subscribe(showGDPRPage => {
          this.email = showGDPRPage.email;
          this.ready = true;
        });
      }
      else{
        this.ready = true;
      }

    }
  }

  public async allowSelection() {
    this.loading = true;
    var result = await this.gdprApiService.update(this.email, this.userConsent, this.researchConsent, this.marketingConsent);
    this.loading = false;

    if (result.isSuccess) {
      var url = this.returnUrl;
      this.router.navigate([url]);
    }
    else {
      this.toastrService.error(result.message)
    }
  }

  public async allowAll() {
    this.userConsent = true;
    this.researchConsent = true;
    this.marketingConsent = true;

    this.loading = true;
    var result = await this.gdprApiService.update(this.email, this.userConsent, this.researchConsent, this.marketingConsent);
    this.loading = false;

    if (result.isSuccess) {
      var url = this.returnUrl;
      this.router.navigate([url]);
    }
    else {
      this.toastrService.error(result.message)
    }

  }

  public goToGeneralConditions() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/generalConditions']));
    window.open(url, '_blank');
  }

  public goToPrivacyPolicy() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/privacyPolicy']));
    window.open(url, '_blank');
  }
}
