import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import {
    UsersGetAllResponseItem,
    UsersGetAllResponse, UsersCreateRequest, UsersEditRequest,
    UsersDeleteRequest, UsersGetByIdResponse, UsersGetAdminsResponseItem,
    UsersGetAdminsResponse, UsersSetPasswordRequest
} from "./models/models";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";

@Injectable({
    providedIn: 'root'
})
export class UsersApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getAllUsers(): Promise<UsersGetAllResponseItem[]> {
        let url = `${this.apiUrls.userUrl}/all`;
        let apiResult: UsersGetAllResponse = await this.http.get<UsersGetAllResponse>(url).toPromise();

        return apiResult.items;
    }

    public async getAllAdmins(id: string): Promise<UsersGetAdminsResponseItem[]> {
        let url = `${this.apiUrls.userUrl}/admins`;
        let params = new HttpParams({ fromObject: { tenantId: id } });
        let apiResult: UsersGetAdminsResponse = await this.http.get<UsersGetAdminsResponse>(url, { params }).toPromise();

        return apiResult.items;
    }

    public async getUserById(id): Promise<UsersGetByIdResponse> {
        let url = `${this.apiUrls.userUrl}/user`;
        let params = new HttpParams({ fromObject: { id: id } });
        let apiResult: UsersGetByIdResponse = await this.http.get<UsersGetByIdResponse>(url, { params }).toPromise();

        return apiResult;
    }

    public async createUser(request: UsersCreateRequest): Promise<ApiResult<void>> {
        let url = `${this.apiUrls.userUrl}/create`;
        return await this.commandHttp.post(url, request);
    }

    public async editUser(request: UsersEditRequest): Promise<ApiResult<void>> {
        let url = `${this.apiUrls.userUrl}/edit`;
        return await this.commandHttp.post(url, request);
    }

    public async deleteUser(request: UsersDeleteRequest): Promise<ApiResult<void>> {
        let url = `${this.apiUrls.userUrl}/delete`;
        return await this.commandHttp.post(url, request);
    }
}