import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { CommandHttpService } from "./command-http.service";
import { LeaderInFocusPersonalNotesGetResponse } from "./models/leaderInFocusPersonalNotesGetResponse";
import { LeaderInFocusPersonalNotesGetResponseItem } from "./models/leaderInFocusPersonalNotesGetResponseItem";

@Injectable({
    providedIn: 'root'
})
export class LeaderInFocusPersonalNoteApiService{
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getPersonalNote(leaderInFocusId: string): Promise<LeaderInFocusPersonalNotesGetResponseItem[]>{
        let params = new HttpParams({ fromObject: { leaderInFocusId: leaderInFocusId } });
        let url = `${this.apiUrls.leaderInFocusPersonalNoteUrl}/all`;
        let apiResult: LeaderInFocusPersonalNotesGetResponse = await this.http.get<LeaderInFocusPersonalNotesGetResponse>(url, {params}).toPromise();

        return apiResult.items; 
    }

    public async addPersonalNote(leaderInFocusId: string, message: string) {
        let url = `${this.apiUrls.leaderInFocusPersonalNoteUrl}/add`;
        let requestBody = { leaderInFocusId: leaderInFocusId, message: message };
        await this.commandHttp.post(url, requestBody);
    }

    public async editPersonalNote(id: string, message: string){
        let url = `${this.apiUrls.leaderInFocusPersonalNoteUrl}/edit`;
        let requestBody = { id: id, message: message};
        await this.commandHttp.post(url, requestBody);
    }

    public async deletePersonalNote(id: string) {
        let url = `${this.apiUrls.leaderInFocusPersonalNoteUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }
}