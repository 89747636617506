import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QuestionnaireRecipientsResendEmailByRecipientIdRequest } from "../api.services/models/models";
import { FormControl } from "@angular/forms";

@Component({
    selector: 'resend-email.dialog',
    templateUrl: 'resend-email.dialog.html',
})
export class ResendEmailDialog {

    public date: FormControl;
    constructor(
        public dialogRef: MatDialogRef<ResendEmailDialog>,
        @Inject(MAT_DIALOG_DATA) public data: QuestionnaireRecipientsResendEmailByRecipientIdRequest) { }

}