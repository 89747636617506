import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ApiUrls{
    public userUrl: string = "api/users";
    public recipientsUrl: string = "api/recipients";
    public recipientGroupsUrl: string = "api/recipientGroups";
    public questionnairesUrl: string = "api/questionnaires";
    public questionnaireRecipientsUrl: string = "api/questionnaireRecipients"
    public companiesUrl: string = "api/companies";
    public templatesUrl: string = "api/templates";
    public templateQuestionsUrl: string = "api/templateQuestions";
    public surveysUrl: string = "api/surveys";
    public reportUrl: string = "api/report";
    public tenantUrl: string = "api/tenants";
    public subscriptionPlanUrl: string = "api/subscriptionPlans";
    public authorizationUrl: string = "api/authorization";
    public leaderInFocusCommentsUrl: string = "api/leaderInFocusComments";
    public leaderInFocusActionsUrl: string = "api/leaderInFocusActions";
    public leaderInFocusPersonalNoteUrl: string = "api/leaderInFocusPersonalNotes";
    public leaderInFocusInsightsUrl: string = "api/leaderInFocusInsights";
    public gdprUrl: string = "api/gDPRCompliances";
}