import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddCompanyDialog } from './add.company.dialog';
import { EditCompanyDialog } from './edit.company.dialog';
import { Router } from '@angular/router';
import { CompaniesGetAllResponseItem } from '../api.services/models/models';
import { CompaniesApiService } from '../api.services/companies.api.service';
import { DeleteCompanyDialog } from './delete.company.dialog';

@Component({
    selector: 'app-companies',
    styleUrls: ['companies.component.scss'],
    templateUrl: './companies.component.html'
})

export class CompaniesComponent implements OnInit {
    public isApiRequestInProgress: boolean = false;
    companies: CompaniesGetAllResponseItem[] = [];
    displayedColumns: string[] = [
        'name',
        'email',
        'numberOfQuestionnaires',
        'numberOfRecipientGroups',
        'actions'];
    dataSource = new MatTableDataSource<CompaniesGetAllResponseItem>(this.companies);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private companiesApiService: CompaniesApiService,
        private addCompanyDialog: MatDialog,
        private editCompanyDialog: MatDialog,
        private deleteCompanyDialog: MatDialog,
        private router: Router,
    ) { }

    async ngOnInit() {
        await this.getCompanies();
    }

    openDialogAddCompany(): void {
        const dialogRef = this.addCompanyDialog.open(AddCompanyDialog, {
            width: '600px',
            data: { name: '', email: '' },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.isApiRequestInProgress = true;
                await this.createCompany(result.name, result.email);
                this.isApiRequestInProgress = false;
            }
        });
    }

    openDialogEditCompany(company): void {
        const dialogRef = this.editCompanyDialog.open(EditCompanyDialog, {
            width: '600px',
            data: { id: company.id, name: company.name, email: company.email },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.editCompany(result.id, result.name, result.email);
            }
        });
    }

    openDialogDeleteCompany(company): void {
        const dialogRef = this.deleteCompanyDialog.open(DeleteCompanyDialog, {
            width: '600px',
            data: { id: company.id, name: company.name },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.deleteCompany(result.id);
            }
        });
    }

    openRecipientGroups(company: any) {
        this.router.navigate([`/clients/${company.id}/${company.name}/recipient-groups`]);
    }

    openQuestionnaires(company: any) {
        this.router.navigate([`/clients/${company.id}/${company.name}/questionnaires`]);
    }

    private async getCompanies() {
        this.companies = await this.companiesApiService.getCompanies();
        this.dataSource = new MatTableDataSource<CompaniesGetAllResponseItem>(this.companies);
        this.dataSource.paginator = this.paginator;
    }

    private async createCompany(companyName: string, companyEmail: string) {
        await this.companiesApiService.createCompany(companyName, companyEmail);
        await this.getCompanies();;
    }

    private async editCompany(companyId: string, companyName: string, companyEmail: string) {
        await this.companiesApiService.editCompany(companyId, companyName, companyEmail);
        await this.getCompanies();;
    }

    private async deleteCompany(companyId: string) {
        await this.companiesApiService.deleteCompany(companyId);
        await this.getCompanies();;
    }
}
