import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TenantsEditRequest } from "src/app/api.services/models/models";
import { TenantsApiService } from "src/app/api.services/tenants.api.service";

@Component({
    selector: 'edit.tenant.dialog',
    templateUrl: 'edit.tenant.dialog.html',
})
export class EditTenantDialog{


    constructor(
        public dialogRef: MatDialogRef<EditTenantDialog>,
        @Inject(MAT_DIALOG_DATA) public data: TenantsEditRequest) { }
    
    onNoClick(): void {
        this.dialogRef.close();
    }
}