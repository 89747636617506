import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ReportApiService } from "src/app/api.services/report.api.service";
import { ReportGenerateReportDataResponse } from "src/app/api.services/models/models";

@Injectable()
export class QuestionnaireReportResolver implements Resolve<ReportGenerateReportDataResponse>{
  constructor(private reportApiService: ReportApiService){}
    
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ReportGenerateReportDataResponse> {
    var questionnaireId = route.paramMap.get('questionnaireId');
    var report = await this.reportApiService.getReportGenerateData(questionnaireId) as ReportGenerateReportDataResponse;

    return report;
  }
}