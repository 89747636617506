import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'change.email.service.password.dialog',
    templateUrl: 'change.email.service.password.dialog.html',
})
export class ChangeEmailServicePasswordDialog {
    public  isActive: false;
    constructor(
        public dialogRef: MatDialogRef<ChangeEmailServicePasswordDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastrService: ToastrService) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    change(): void {
        !this.isActive;
    }
}