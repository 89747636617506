import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { PieGridComponent } from "@swimlane/ngx-charts";
import { LeaderInFocusInsightsApiService } from "src/app/api.services/leaderInFocuse.insights.api.service";
import { LeaderInFocusInsightsGetIndexPerGroupResponseItem } from "src/app/api.services/models/leaderInFocusInsightsGetIndexPerGroupResponseItem";
import { IndexPerGroupInfoDialog } from "./indexPerGroupInfo.dialog";

@Component({
    selector: 'realityCheck-index-per-group',
    templateUrl: './realityCheck-index-per-group.component.html',
    styleUrls: ['./realityCheck-index-per-group.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RealityCheckIndexPerGroupComponent implements OnInit {

    private _actionLevel = "First";
    @Input() set actionLevel(value: string) {
        if (value != "") {
            this._actionLevel = value;
        }
    }
    get actionLevel(): string {
        return this._actionLevel;
    }
    @ViewChild(PieGridComponent) pieChart: PieGridComponent;

    public questionnaireId: string;
    public realityCheckIndex: number;
    public data: LeaderInFocusInsightsGetIndexPerGroupResponseItem[];
    public items: any[] = [];
    public view: any[] = [150, 150];

    public colorScheme: any;
    public colorSchemeFirst = {
        domain: ['#D457CD']
    };
    public colorSchemeSecond = {
        domain: ['#A23AC6']
    };
    public colorSchemeThird = {
        domain: ['#332070']
    };

    constructor(
        private route: ActivatedRoute,
        private infoDialog: MatDialog,
        private leaderInFocusInsightsApiService: LeaderInFocusInsightsApiService,
    ) { }

    ngOnInit(): void {
        this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
        this.checkActiveLevel();
        this.getRealityCheckIndexPerGroup();
    }

    public openInfoDialog(): void {
        const elemRect = (event.target as HTMLElement).getBoundingClientRect()
        const dialogRef = this.infoDialog.open(IndexPerGroupInfoDialog, {
            width: '300px',
            height: '300px',
            autoFocus: false,
            position: {
                top: elemRect.bottom + 20 + "px",
                left: elemRect.right - 250 + "px"
            }
        });
    }

    public checkActiveLevel() {
        if (this.actionLevel == 'First') {
            this.colorScheme = this.colorSchemeFirst;
        }
        else if (this.actionLevel == 'Second') {
            this.colorScheme = this.colorSchemeSecond;
        }
        else if (this.actionLevel == 'Тhird') {
            this.colorScheme = this.colorSchemeThird;
        }
    }

    private async getRealityCheckIndexPerGroup() {
        this.data = await this.leaderInFocusInsightsApiService.getIndexPerGroup(this.questionnaireId);
        this.data.forEach(element => {
            var elementData = [{ name: element.title.length > 12 ? element.title.slice(0, 9) + '...' : element.title, value: element.index }];
            this.items.push(elementData);
        });
    }
}