import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { CommandHttpService } from "./command-http.service";
import { LeaderInFocusInsightsGetBaseDataResponse } from "./models/leaderInFocusInsightsGetBaseDataResponse"
import { LeaderInFocusInsightsGetIndexResponse } from "./models/leaderInFocusInsightsGetIndexResponse"
import { LeaderInFocusInsightsGetIndexPerGroupResponse } from "./models/leaderInFocusInsightsGetIndexPerGroupResponse"
import { LeaderInFocusInsightsGetIndexPerGroupResponseItem } from "./models/leaderInFocusInsightsGetIndexPerGroupResponseItem"
import { LeaderInFocusInsightsGetAveragePerAreaResponse } from "./models/leaderInFocusInsightsGetAveragePerAreaResponse";
import { LeaderInFocusInsightsGetAveragePerAreaResponseGroup } from "./models/leaderInFocusInsightsGetAveragePerAreaResponseGroup";

@Injectable({
    providedIn: 'root'
})
export class LeaderInFocusInsightsApiService{
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getBaseData(questionnaireId: string, leaderInFocusId: string): Promise<LeaderInFocusInsightsGetBaseDataResponse>{
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId,leaderInFocusId: leaderInFocusId } });
        let url = `${this.apiUrls.leaderInFocusInsightsUrl}/base-data`;
        let apiResult: LeaderInFocusInsightsGetBaseDataResponse = await this.http.get<LeaderInFocusInsightsGetBaseDataResponse>(url, {params}).toPromise();

        return apiResult; 
    }   

    public async getIndex(questionnaireId: string): Promise<number>{
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.leaderInFocusInsightsUrl}/index`;
        let apiResult: LeaderInFocusInsightsGetIndexResponse = await this.http.get<LeaderInFocusInsightsGetIndexResponse>(url, {params}).toPromise();

        return apiResult.index; 
    } 

    public async getIndexPerGroup(questionnaireId: string): Promise<LeaderInFocusInsightsGetIndexPerGroupResponseItem[]>{
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.leaderInFocusInsightsUrl}/index-per-group`;
        let apiResult: LeaderInFocusInsightsGetIndexPerGroupResponse = await this.http.get<LeaderInFocusInsightsGetIndexPerGroupResponse>(url, {params}).toPromise();

        return apiResult.items; 
    } 

    public async getAveragePerArea(questionnaireId: string): Promise<LeaderInFocusInsightsGetAveragePerAreaResponseGroup[]>{
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.leaderInFocusInsightsUrl}/average-per-area`;
        let apiResult: LeaderInFocusInsightsGetAveragePerAreaResponse = await this.http.get<LeaderInFocusInsightsGetAveragePerAreaResponse>(url, {params}).toPromise();

        return apiResult.groups; 
    } 
}