import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'delete-comment-dialog',
    templateUrl: 'delete.comment.dialog.html',
})
export class DeleteCommentDialog {

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
       this.dialogRef.close();
    }
}