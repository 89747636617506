import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeaderInFocusInsightsApiService } from "../api.services/leaderInFocuse.insights.api.service";
import { LeaderInFocusInsightsGetBaseDataResponse } from "../api.services/models/leaderInFocusInsightsGetBaseDataResponse";
import { AuthenticationLeaderInFocusService } from "../authorization/authenticationLeaderInFocus.service";


@Component({
    selector: 'leaderInFocus-insight',
    templateUrl: './leaderInFocus-insight.component.html',
    styleUrls: ['./leaderInFocus-insight.component.scss']
})
export class LeaderInFocusInsightComponent implements OnInit {

    public actionLevel = "First";
    public questionnaireId:string|null;
    public leaderInFocusId:string|null;
    public baseData: LeaderInFocusInsightsGetBaseDataResponse;
    public isLoadData = false;
    public name = "";
    public isLeaderInFocus: boolean = false;
    public actions: number;
    public date: Date;
    constructor(
        private route: ActivatedRoute,
        private leaderInFocusInsightsApiService: LeaderInFocusInsightsApiService,
        private authenticationLeaderInFocus: AuthenticationLeaderInFocusService
    ) { }


    ngOnInit(): void {
        this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
        this.leaderInFocusId = this.route.snapshot.paramMap.get("leaderInFocusId");
        if (this.leaderInFocusId == null) {
            this.isLeaderInFocus = true;
            var auth = this.authenticationLeaderInFocus.getAuth();
            this.leaderInFocusId = auth.userId;
        }
        this.getBaseData();
    }


    private async getBaseData() {
        this.baseData = await this.leaderInFocusInsightsApiService.getBaseData(this.questionnaireId, this.leaderInFocusId);
        this.name = this.baseData.name;
        this.date = this.baseData.date;
        this.actionLevel = this.baseData.actionLevel;
        this.actions = this.baseData.actions
        this.isLoadData = true;
    }
}