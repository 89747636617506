import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { CommandHttpService } from "./command-http.service";
import { LeaderInFocusActionsGetResponse } from "./models/leaderInFocusActionsGetResponse";

@Injectable({
    providedIn: 'root'
})
export class LeaderInFocusActionApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getAction(questionnaireId: string): Promise<LeaderInFocusActionsGetResponse> {
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.leaderInFocusActionsUrl}/all`;
        let apiResult: LeaderInFocusActionsGetResponse = await this.http.get<LeaderInFocusActionsGetResponse>(url, { params }).toPromise();

        return apiResult;
    }

    public async addAction(LeaderInFocusId: string, questionnaireId: string, message: string, done: boolean) {
        let url = `${this.apiUrls.leaderInFocusActionsUrl}/add`;
        let requestBody = { LeaderInFocusId: LeaderInFocusId, questionnaireId: questionnaireId, message: message, done: done };
        await this.commandHttp.post(url, requestBody);
    }

    public async editAction(id: string, message: string, done: boolean) {
        let url = `${this.apiUrls.leaderInFocusActionsUrl}/edit`;
        let requestBody = { id: id, message: message, done: done };
        await this.commandHttp.post(url, requestBody);
    }

    public async deleteAction(id: string) {
        let url = `${this.apiUrls.leaderInFocusActionsUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }

    public async checkDone(id: string, done: boolean) {
        let url = `${this.apiUrls.leaderInFocusActionsUrl}/complete`;
        let requestBody = { id: id, done: done };
        await this.commandHttp.post(url, requestBody);
    }



}