import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { CommandHttpService } from "./command-http.service";
import { LeaderInFocusCommentsGetResponse } from "./models/leaderInFocusCommentsGetResponse";
import { LeaderInFocusCommentsGetResponseItem } from "./models/leaderInFocusCommentsGetResponseItem";

@Injectable({
    providedIn: 'root'
})
export class LeaderInFocusCommentApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getComments(questionnaireId: string): Promise<LeaderInFocusCommentsGetResponseItem[]> {
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.leaderInFocusCommentsUrl}/all`;
        let apiResult: LeaderInFocusCommentsGetResponse = await this.http.get<LeaderInFocusCommentsGetResponse>(url, { params }).toPromise();

        return apiResult.items;
    }

    public async addComment(LeaderInFocusId: string, questionnaireId: string, message: string) {
        let url = `${this.apiUrls.leaderInFocusCommentsUrl}/add`;
        let requestBody = { LeaderInFocusId: LeaderInFocusId, questionnaireId: questionnaireId, message: message };
        await this.commandHttp.post(url, requestBody);
    }

    public async editComment(id: string, message: string) {
        let url = `${this.apiUrls.leaderInFocusCommentsUrl}/edit`;
        let requestBody = { id: id, message: message };
        await this.commandHttp.post(url, requestBody);
    }

    public async deleteComment(id: string) {
        let url = `${this.apiUrls.leaderInFocusCommentsUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }
}