import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'delete.user.dialog',
    templateUrl: 'delete.user.dialog.html',
})
export class DeleteUserDialog {

    constructor(
        public dialogRef: MatDialogRef<DeleteUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}