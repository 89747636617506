import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QuestionnaireRecipientsEditRecipientRequest, RecipientGroupsGetByCompanyIdResponseItem } from "../api.services/models/models";
import { RecipientGroupsApiService } from "../api.services/recipient.groups.api.service";

@Component({
    selector: 'edit.recipient.dialog',
    templateUrl: 'edit.recipient.dialog.html',
})
export class EditQuestionnareRecipientDialog implements OnInit {

    public recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[];

    constructor(
        private recipientGroupApi: RecipientGroupsApiService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: QuestionnaireRecipientsEditRecipientRequest) { }


    public async ngOnInit() {
        this.recipientGroups = await this.recipientGroupApi.getRecipientGroupsByCompanyId(this.data.companyId);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}