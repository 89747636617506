import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { SubscriptionPlansGetAllByTenantIdResponseItem } from "src/app/api.services/models/subscriptionPlansGetAllByTenantIdResponseItem";
import { SubscriptionPlanApiService } from "src/app/api.services/subscription.plan.api.service";
import { AddSubscriptionPlanDialog } from "../add-subscription-plans/add.subscription.plan.dialog";
import { ToastrService } from 'ngx-toastr';
import { DeleteSusbcriptionPlanDialog } from "../delete-subscription-plans/delete.susbcription.plan.dialog";

@Component({
    templateUrl: './subscription.plans.list.component.html'
})
export class SubscriptionPlansListComponent implements OnInit {
    public tenantId: string;
    public subscriptionPlans: SubscriptionPlansGetAllByTenantIdResponseItem[] = [];
    public subscriptionPlansDisplayedColumns: string[] = [
        'numberOfReports',
        'description',
        'cost',
        'actions'];

    public subscriptionPlansDataSource = new MatTableDataSource<SubscriptionPlansGetAllByTenantIdResponseItem>(this.subscriptionPlans);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private subscriptionPlanApiService: SubscriptionPlanApiService,
        private openDialog: MatDialog,
        private route: ActivatedRoute,
        private toastrService : ToastrService,
    ) { }

    public async ngOnInit() {
        this.tenantId = this.route.snapshot.paramMap.get('tenantId');
        await this.getSubscriptionPlans();
    }

    public openDialogAddSubscriptionPlan() { 
        const dialogRef = this.openDialog.open(AddSubscriptionPlanDialog, {
            width: '600px',
            data: {   
                numberOfReports: 0,
                description: '',
                cost: 0,
                tenantId: this.tenantId
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResalt = await this.subscriptionPlanApiService.create(result);
                if(apiRequestResalt.isSuccess){
                    this.toastrService.success("Subscription Plan was successfully saved.");
                    this.getSubscriptionPlans();
                }
                else {
                    this.toastrService.error("Subscription Plan was not saved.");
                }
            }
        }); 
    }

    public openDialogDeleteSubscriptionPlans(element: SubscriptionPlansGetAllByTenantIdResponseItem) {
        const dialogRef = this.openDialog.open(DeleteSusbcriptionPlanDialog, {
            width: '600px',
            data: {  
                id: element.id
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResalt = await this.subscriptionPlanApiService.delete(result.id);
                if(apiRequestResalt.isSuccess){
                    this.toastrService.success("Subscription Plan was successfully deleted.");
                    this.getSubscriptionPlans();
                }
                else {
                    this.toastrService.error("Subscription Plan was not deleted.");
                }
            }
        });
    }

    private async getSubscriptionPlans() {
        this.subscriptionPlans = await this.subscriptionPlanApiService.getAllByTenantId(this.tenantId);
        this.subscriptionPlansDataSource = new MatTableDataSource<SubscriptionPlansGetAllByTenantIdResponseItem>(this.subscriptionPlans);
        this.subscriptionPlansDataSource.paginator = this.paginator;
    }
}