export class AddQuestionnaire {
    companyId: string;
    questionnaireName: string;
    templateId: string;
    questions: Question[];
    recipient: Recipient[];
    constructor(companyId: string,
        questionnaireName: string,
        templateId: string,
        questions: Question[],
        recipient: Recipient[],){
        this.companyId = companyId;
        this.questionnaireName = questionnaireName;
        this.templateId = templateId;
        this.questions = questions;
        this.recipient = recipient;
    }
}

export class QuestionnaireTemplate {
    name: string;
    id: string;
    area: Area[];
}

export class Area{
    name: string;
    questions: TemplateQuestion[];
}

export class Question {
    id: string;
    title: string;
    description: string;
    questionType: string;
}

export class Company {
    id: string;
    name: string;
}

export class RecipientGroups{
    id: string;
    name: string;
    recipient: Recipient[]
}

export class LeaderInFocus{
    email: string;
    firstName: string;
    lastName: string;
    groupId: string; 
    recipients:  Recipient[]
}

export class Recipient{
    email: string;
    firstName: string;
    lastName: string;
    groupId: string; 
}

export class TemplateQuestion{
    title: string;
    description: string;
    questionType: string;
}