import { Directive, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
 
@Directive({
  selector: '[keyUp]'
})
export class KeyUpDirective {
  @Input() keyUp;
  constructor() {}
  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {

      if (event.which == 8) {
        const field = document.getElementById(this.keyUp);
        field.focus();
      }
  }
}
