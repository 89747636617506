import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResult } from "../shared/models/api-result";
import { ApiUrls } from "./api-urls";
import { CommandHttpService } from "./command-http.service";
import { GDPRCompliancesCheckGDPRByTokenResponse } from "./models/gDPRCompliancesCheckGDPRByTokenResponse";
import { GDPRCompliancesGetResponse } from "./models/gDPRCompliancesGetResponse";

@Injectable({
    providedIn: 'root'
})
export class GDPRApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public checkByEmail(email: string): Observable<GDPRCompliancesGetResponse> {
        let params = new HttpParams({ fromObject: { email: email } });
        let url = `${this.apiUrls.gdprUrl}/check-by-email`;
        return this.http.get<GDPRCompliancesGetResponse>(url, { params });
    }

    public checkByToken(token: string): Observable<GDPRCompliancesCheckGDPRByTokenResponse> {
        let params = new HttpParams({ fromObject: { token: token } });
        let url = `${this.apiUrls.gdprUrl}/check-by-token`;
        return this.http.get<GDPRCompliancesCheckGDPRByTokenResponse>(url, { params });

    }
    public async update(email: string, userConsent: boolean, researchAndDevelopmentConsent: boolean, marketingConsent: boolean) : Promise<ApiResult<void>> {
        let url = `${this.apiUrls.gdprUrl}/update`;
        let requestBody = { email: email, userConsent: userConsent, researchAndDevelopmentConsent: researchAndDevelopmentConsent, marketingConsent: marketingConsent };
        return await this.commandHttp.post(url, requestBody);
    }
}