import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderInFocuseGuard } from './authorization/guards/leaderInFocus-guard.service';
import { LeaderInFocusInsightComponent } from './leaderInFocus-insight/leaderInFocus-insight.component';
import { LeaderInFocuseReportsComponent } from './leaderInFocus-reports/leaderInFocus-reports.component';
import { CommentsAndActionsComponent } from './leaderInFocus/commentsAndActions/commentsAndActions.component';
import { PersonalNotesComponent } from './leaderInFocus/personal-notes/personal-notes.component';
import { QuestionnaireRecipientsComponent } from './questionnaire-recipients/questionnaire-recipients.component';
import { QuestionnaireReportComponent } from './questionnaire/questionnaire-report/questionnaire-report.component';
import { QuestionnaireReportResolver } from './questionnaire/questionnaire-report/questionnaire-report.resolver.service';
const routes: Routes = [
 {
    path: '',
    canActivate: [LeaderInFocuseGuard],
    canActivateChild: [LeaderInFocuseGuard],
    children: [
     { path: 'overview', component: LeaderInFocuseReportsComponent  },
     { path: 'reports/:questionnaireId', component: QuestionnaireReportComponent, resolve: { report: QuestionnaireReportResolver } },
     { path: 'notes', component: PersonalNotesComponent  },
     { path: 'insight/:questionnaireId', component: LeaderInFocusInsightComponent  },
     { path: 'insight/:questionnaireId/commentsAndActions', component: CommentsAndActionsComponent  },
     { path: 'reports/:questionnaireId/:questionnaireName/:companyId/recipients', component: QuestionnaireRecipientsComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LeaderInFocusRoutingModule { }

