import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector:'[message-layout]',
    templateUrl: './message-layout.component.html',
})
export class MessageLayoutComponent {
   
}
