import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Guid } from "guid-typescript";
import { LeaderInFocusPersonalNoteApiService } from "src/app/api.services/leaderInFocus.personal-note.api.service";
import { LeaderInFocusPersonalNotesGetResponseItem } from "src/app/api.services/models/leaderInFocusPersonalNotesGetResponseItem";
import { AuthenticationLeaderInFocusService } from "src/app/authorization/authenticationLeaderInFocus.service";
import { DeletePersonalNotesDialog } from "./delete.personal-notes.dialog";
import { PersonalNotesInfoDialog } from "./infoPersonalNotes.dialog";


@Component({
  selector: 'personal-notes',
  templateUrl: './personal-notes.component.html',
  styleUrls: ['./personal-notes.component.scss']
})
export class PersonalNotesComponent implements OnInit {

  public leaderInFocusId: string = "";
  public personalNotes: LeaderInFocusPersonalNotesGetResponseItem[] = [];
  public leaderInFocusPersonalNotes: LeaderInFocusPersonalNote[] = [];
  public personalNotesColumns: string[] = [
    'message',
    'showMore',
    'date',
    'actions'];
  public personalNotesDataSource = new MatTableDataSource<LeaderInFocusPersonalNotesGetResponseItem>(this.leaderInFocusPersonalNotes);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private leaderInFocusPersonalNoteApiService: LeaderInFocusPersonalNoteApiService,
    private deletePersonalNoteDialog: MatDialog,
    private infoDialog: MatDialog,
    private authenticationLeaderInFocus: AuthenticationLeaderInFocusService
  ) { }

  async ngOnInit(): Promise<void> {
    var auth = this.authenticationLeaderInFocus.getAuth();
    this.leaderInFocusId = auth.userId;
    await this.getPersonalNote();
  }

  public openInfoDialog(): void {
    const elemRect = (event.target as HTMLElement).getBoundingClientRect()
    const dialogRef = this.infoDialog.open(PersonalNotesInfoDialog, {
        width: '300px',
        height: '300px',
        autoFocus: false,
        position: {
            top: elemRect.bottom + 20 + "px",
            left: elemRect.right - 250 + "px"
        }
    });
}

  public addPersonalNoteAction() {
    var personalNote = new LeaderInFocusPersonalNote();
    personalNote.message = "";
    personalNote.isAdded = true;
    personalNote.isEdit = true;
    personalNote.date = new Date(Date.now());
    personalNote.readMore = false;
    personalNote.readLess = false;
    personalNote.id = Guid.create().toString();
    this.leaderInFocusPersonalNotes.unshift(personalNote);
    this.personalNotesDataSource = new MatTableDataSource<LeaderInFocusPersonalNotesGetResponseItem>(this.leaderInFocusPersonalNotes);
    this.personalNotesDataSource.paginator = this.paginator;
  }

  public onEdit(personalNote: any): void {
    var leaderInFocusPersonalNotesIndex = this.leaderInFocusPersonalNotes.findIndex(x => x.id == personalNote.id);
    if (leaderInFocusPersonalNotesIndex != -1) {
      this.leaderInFocusPersonalNotes[leaderInFocusPersonalNotesIndex].isEdit = true;
    }
  }

  public onSave(personalNote: any): void {
    if (personalNote.isAdded) {
      this.addPersonalNote(personalNote.message);
    }
    else {
      this.editPersonalNote(personalNote.id, personalNote.message);
    }
  }

  public onRemove(personalNote: any): void {
    if(personalNote.isAdded){
      this.leaderInFocusPersonalNotes.shift();
    this.personalNotesDataSource = new MatTableDataSource<LeaderInFocusPersonalNote>(this.leaderInFocusPersonalNotes);
    this.personalNotesDataSource.paginator = this.paginator;
    }
    else if(personalNote.isEdit && !personalNote.isAdded){
      var leaderInFocusPersonalNotesIndex = this.leaderInFocusPersonalNotes.findIndex(x => x.id == personalNote.id);
      if (leaderInFocusPersonalNotesIndex != -1) {
        this.leaderInFocusPersonalNotes[leaderInFocusPersonalNotesIndex].isEdit = false;
      }
    }
    else if(!personalNote.isAdded && !personalNote.isEdit){
      const dialogRef = this.deletePersonalNoteDialog.open(DeletePersonalNotesDialog, {
        width: '600px',
        data: { personalNote: personalNote }
      });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.deletePersonalNote(result.id);
      }
    });}
  }

  public showMore(personalNote) {
    personalNote.readLess = true;
    personalNote.readMore = false;
  }

  public showLess(personalNote) {
    personalNote.readLess = false;
    personalNote.readMore = true;
  }

  private async getPersonalNote() {
    this.leaderInFocusPersonalNotes = [];
    this.personalNotes = await this.leaderInFocusPersonalNoteApiService.getPersonalNote(this.leaderInFocusId);

    this.personalNotes.forEach(element => {
      var personalNote = new LeaderInFocusPersonalNote();
      personalNote.date = element.date;
      personalNote.id = element.id;
      personalNote.message = element.message;
      personalNote.readMore = element.message.length > 110;
      personalNote.readLess = false;
      personalNote.isEdit = false;
      personalNote.isAdded = false;
      this.leaderInFocusPersonalNotes.push(personalNote);
    });

    this.personalNotesDataSource = new MatTableDataSource<LeaderInFocusPersonalNote>(this.leaderInFocusPersonalNotes);
    this.personalNotesDataSource.paginator = this.paginator;
  }

  private async editPersonalNote(id: string, message: string) {
    await this.leaderInFocusPersonalNoteApiService.editPersonalNote(id, message);
    await this.getPersonalNote();
  }

  private async addPersonalNote(message: string) {
    await this.leaderInFocusPersonalNoteApiService.addPersonalNote(this.leaderInFocusId, message);
    await this.getPersonalNote();
  }

  private async deletePersonalNote(id: string) {
    await this.leaderInFocusPersonalNoteApiService.deletePersonalNote(id);
    await this.getPersonalNote();
  }


}


class LeaderInFocusPersonalNote {
  id?: string;
  message?: string;
  readMore: boolean;
  readLess: boolean;
  date?: Date;
  isEdit: boolean;
  isAdded: boolean;
}