import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { SurveysGetQuestionnaireResponse } from "../api.services/models/models";
import { SurveysApiService } from "../api.services/surveys.api.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class SurveysReslover implements Resolve<SurveysGetQuestionnaireResponse>{
  constructor(private surveysApiService: SurveysApiService){}
    
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SurveysGetQuestionnaireResponse> {
    var token = route.paramMap.get('token');
    var surveys = await this.surveysApiService.getSurveys(token) as SurveysGetQuestionnaireResponse;

    return surveys;
  }
}