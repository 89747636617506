import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionPlanApiService } from 'src/app/api.services/subscription.plan.api.service';
import { AuthenticationService } from 'src/app/authorization/authentication.service';
import { InformationalDialog } from './informational.dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector:'[app-layout]',
    templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent {
    role:string;
    sidenavWidth = 4;
    showToggle = false;
    checked = true;
    show = true;
    sideNav = true;
    sidenavWidthButton = 15;
    fix = true;
    mode = 'side';
    isDisabled = false;

    constructor(private router : Router,
      private authenticationService: AuthenticationService,
      private subscriptionPlanApiService: SubscriptionPlanApiService,
      private informationalDialog: MatDialog) { 
     this.slideToggle();
    }
  
    async ngOnInit() {
      await this.canCreateQuestionnaire();

      this.role = this.authenticationService.getRole();
    }
    
    slideToggle(){
      if(this.checked == true){
        this.sidenavWidth = 15;
        this.sidenavWidthButton = 210;
        this.showToggle = true;
        this.checked = false;
        this.show = true;
        this.fix = true; 
      }
      else{        
        this.sidenavWidth = 4;
        this.sidenavWidthButton = 56;
         this.showToggle = false;
         this.checked = true;
         this.fix = false; 
          this.show = false;
      }
    }

    toggle(){
      this.sidenavWidth = 15;
      if(this.sideNav == true){
        this.sideNav = false;
        this.sidenavWidthButton = 0;
       }
       else{
        this.sideNav = true;
        this.sidenavWidthButton = 0;
       }
    }

    public logOff(){
        this.authenticationService.logout();
        this.router.navigate(["/login"]);
    }

    public createQuestionnaire(){
      if(this.isDisabled){
        const dialogRef = this.informationalDialog.open(InformationalDialog, {
          width: '600px',
          autoFocus: false,
      });

      dialogRef.afterClosed().subscribe(async (result) => {
         return;
      });
        
      }

      this.router.navigate([`/questionnaire/add`]);
    }

    private async canCreateQuestionnaire() {
      this.isDisabled = !(await this.subscriptionPlanApiService.canCreateQuestionnaire());
    }
}

