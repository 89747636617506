import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[autoTab]'
})
export class AutoTabDirective {
  @Input() autoTab;
  constructor() { }
  @HostListener('input', ['$event.target']) onInput(input) {
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    if (length >= maxLength && this.autoTab) {
      const field = document.getElementById(this.autoTab);
      const value = (field as HTMLInputElement).value;
      if (field && value == "") {
        field.focus();
      }
      else if (value != "") {
        [].forEach.call(document.querySelectorAll("input[type=text]"), (node, index) => {
          // And set input value to the relative character
          if (node.value != "") {
            const field = document.getElementById(index + 1);
            if (field) {
              field.focus();
            }
          }
        });
      }
    }
  }
}
