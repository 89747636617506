import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UsersGetAllResponseItem } from 'src/app/api.services/models/usersGetAllResponseItem';
import { UsersApiService } from 'src/app/api.services/users.api.service';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';
import { ToastrService } from 'ngx-toastr';
import { AddUserDialog } from '../add-user/add.user.dialog';
import { UsersDeleteRequest, UsersEditRequest } from 'src/app/api.services/models/models';
import { DeleteUserDialog } from '../delete-user/delete.user.dialog';
import { EditUserDialog } from '../edit-user/edit.user.dialog';

@Component({
    templateUrl: './user.list.component.html'
})
export class UserListComponent implements OnInit {
    usersDisplayedColumns: string[] = [
        'email',
        'role',
        'actions'];

    usersGroups: UsersGetAllResponseItem[] = [];
    usersDataSource = new MatTableDataSource<UsersGetAllResponseItem>(this.usersGroups);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   
    constructor(private usersApiService: UsersApiService, 
         private toastrService : ToastrService,
        private openDialog: MatDialog,
        private authorizationApiService: AuthorizationApiService){}

    public async ngOnInit() {
        await this.getUsers();
    }

    private async getUsers(){
        this.usersGroups = await this.usersApiService.getAllUsers();
        this.usersDataSource = new MatTableDataSource<UsersGetAllResponseItem>(this.usersGroups);
        this.usersDataSource.paginator = this.paginator;
    }

    openDialogAddUser(): void {
        const dialogRef = this.openDialog.open(AddUserDialog, {
            width: '600px',
            data: {   
                email: '',
                role: '',
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResalt = await this.usersApiService.createUser(result);
                if(apiRequestResalt.isSuccess){
                    this.toastrService.success("User was successfully saved.");
                    this.getUsers();
                }
                else {
                    this.toastrService.error("User was not saved.");
                }
            }
        });   
    }

  

    openDialogEditUser(element: UsersEditRequest): void {
        const dialogRef = this.openDialog.open(EditUserDialog, {
            width: '600px',
            data: {   
                id: element.id,
                email: element.email,
                role: element.role
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResalt = await this.usersApiService.editUser(result);
                if(apiRequestResalt.isSuccess){
                    this.toastrService.success("User was successfully edited.");
                    this.getUsers();
                }
                else {
                    this.toastrService.error("User was not edited.");
                }
            }
        });  
    }

    openDialogDeleteUser(element: UsersDeleteRequest): void {
        const dialogRef = this.openDialog.open(DeleteUserDialog, {
            width: '600px',
            data: {   
                id: element.id,
                email: element.email,
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResalt = await this.usersApiService.deleteUser(result);
                if(apiRequestResalt.isSuccess){
                    this.toastrService.success("User was successfully deleted.");
                    this.getUsers();
                }
                else {
                    this.toastrService.error("User was not deleted.");
                }
            }
        });   
    }

    public async resetPassword(element: UsersGetAllResponseItem): Promise<void> {
        var apiRequestResalt = await this.authorizationApiService.setPasswordRequest(element.id);  
        if(apiRequestResalt.isSuccess){
            this.toastrService.success("Reset password request was successfully sent.");
            this.getUsers();
        }
        else {
            this.toastrService.error("Reset password request was not sent.");
        }
    }
}