import { OnInit, Component, ViewChild } from "@angular/core";
import { QuestionnairesGetAllByCompanyIdResponseItem } from "src/app/api.services/models/models";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestionnairesApiService } from "src/app/api.services/questionnaires.api.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteQuestionnaireDialog } from "./delete.questionnaire.dialog";
import { SubscriptionPlanApiService } from "src/app/api.services/subscription.plan.api.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-questionnaires.list',
  templateUrl: './questionnaires.list.component.html'
})
export class QuestionnairesListComponent implements OnInit {
  public companyId: string = "";
  public companyName: string = "";
  public questionnaires: QuestionnairesGetAllByCompanyIdResponseItem[] = [];
  public isDisabled: boolean = false;
  public toster: ToastrService
  public createButtonTooltip: string = "Click to Create";
  public questionnairesDisplayedColumns: string[] = [
    'title',
    'leaderInFocusName',
    'description',
    'numberOfRecipients',
    'numberOfAnswered',
    'numberOfNotAnswered',
    'action',
    'insight',
    'report',
    'delete'];
    public questionnairesDataSource = new MatTableDataSource<QuestionnairesGetAllByCompanyIdResponseItem>(this.questionnaires);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private questionnairesApiService: QuestionnairesApiService,
    private subscriptionPlanApiService: SubscriptionPlanApiService,
    private route: ActivatedRoute,
    private router: Router,
    private deleteQuestionnaireDialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.companyName = this.route.snapshot.paramMap.get('companyName');
    await this.canCreateQuestionnaire();
    await this.getQuestionnaires();
  }

  public addQuestionnaires() {
    this.router.navigate([`/questionnaire/add`]);
  }

  public openQuestionnaireRecipents(questionnaire: any) {
    this.router.navigate([`/clients/${this.companyId}/${this.companyName}/questionnaires/${questionnaire.id}/${questionnaire.title}/recipients`]);
  }

  public openDialogDeleteQuestionnaire(questionnaire: any): void {
    const dialogRef = this.deleteQuestionnaireDialog.open(DeleteQuestionnaireDialog, {
      width: '600px',
      data: { id: questionnaire.id, name: questionnaire.title },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.deleteQuestionnaire(result.id);
      }
    });
  }

  private async getQuestionnaires() {
    this.questionnaires = await this.questionnairesApiService.getQuestionnairesByCompanyId(this.companyId);
    this.questionnairesDataSource = new MatTableDataSource<QuestionnairesGetAllByCompanyIdResponseItem>(this.questionnaires);
    this.questionnairesDataSource.paginator = this.paginator;
  }

  private async deleteQuestionnaire(id: string) {
    await this.questionnairesApiService.deleteQuestionnaire(id);
    await this.getQuestionnaires();;
  }

  private async canCreateQuestionnaire() {
    this.isDisabled = !(await this.subscriptionPlanApiService.canCreateQuestionnaire());

    this.createButtonTooltip = this.isDisabled
     ? "You cannot create new Questionnaire!"
     : "Click to Create";
  }
}