import { NgModule } from "@angular/core";
import { AdminRoutingModule } from "./admin-routing.module";
import { TenantListComponent } from "./tenant/tenant-list/tenant.list.component";
import { UserListComponent } from "./users/user-list/user.list.component";
import { SharedModule } from "../shared/shared.module";
import { AngularMaterialModule } from "../shared/modules/angular-material.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AddTenantDialog } from "./tenant/add-tenent/add.tenant.dialog";
import { EditTenantDialog } from "./tenant/edit-tenant/edit.tenant.dialog";
import { DeleteTenantDialog } from "./tenant/delete-tenant/delete.tenant.dialog";
import { ChangeEmailServicePasswordDialog } from "./tenant/change-email-service-password/change.email.service.password.dialog";
import { AddUserDialog } from "./users/add-user/add.user.dialog";
import { DeleteUserDialog } from "./users/delete-user/delete.user.dialog";
import { EditUserDialog } from "./users/edit-user/edit.user.dialog";
import { AdminUserListComponent } from "./admin-users/admin-list/admin.user.list.component";
import { AddAdminDialog } from "./admin-users/add-admin/add.admin.dialog";
import { DeleteAdminDialog } from "./admin-users/delete-admin/delete.admin.dialog";
import { EditAdminDialog } from "./admin-users/edit-admin/edit.admin.dialog";
import { SubscriptionPlansListComponent } from "./subscription-plans/subscription-plans-list/subscription.plans.list.component";
import { AddSubscriptionPlanDialog } from "./subscription-plans/add-subscription-plans/add.subscription.plan.dialog";
import { DeleteSusbcriptionPlanDialog } from "./subscription-plans/delete-subscription-plans/delete.susbcription.plan.dialog";

@NgModule({
    imports: [
        AdminRoutingModule,
        CommonModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        SharedModule
    ],
    declarations: [
        TenantListComponent,
        UserListComponent,
        AddTenantDialog,
        EditTenantDialog,
        DeleteTenantDialog,
        ChangeEmailServicePasswordDialog,
        AddUserDialog,
        DeleteUserDialog,
        EditUserDialog,
        AdminUserListComponent,
        AddAdminDialog,
        DeleteAdminDialog,
        EditAdminDialog,
        SubscriptionPlansListComponent,
        AddSubscriptionPlanDialog,
        DeleteSusbcriptionPlanDialog
    ]
})

export class AdminModule { }