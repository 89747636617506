import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GDPRApiService } from 'src/app/api.services/gdpr.api.service';
import { AuthenticationService } from 'src/app/authorization/authentication.service';
import { ThrowStmt } from '@angular/compiler';
import { AuthenticationLeaderInFocusService } from 'src/app/authorization/authenticationLeaderInFocus.service';

const jwtHelper = new JwtHelperService();
@Injectable({
    providedIn: 'root'
})
export class GDPRGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private gdprApiService: GDPRApiService,
        private authenticationService: AuthenticationService,
        private authenticationLeaderInFocusService: AuthenticationLeaderInFocusService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

        let isLoggedIn: boolean = this.authenticationService.isLoggedIn() || this.authenticationLeaderInFocusService.isLoggedIn();

        // If the user is logged in, redirect to the check GDPR by email
        if (isLoggedIn) {
            var email = this.authenticationService.isLoggedIn() ? this.authenticationService.getAuth().email : this.authenticationLeaderInFocusService.getAuth().email;
            return this.gdprApiService.checkByEmail(email).pipe(flatMap(showGDPRPage => {
                if (showGDPRPage.showGDPRPage == false) {
                    return of(true);
                }
                else {
                    this.router.navigate(['/gdpr'], { queryParams: { returnUrl: state.url } });
                    return of(false);
                }
            }));
        }
        else {
            var token = route.paramMap.get('token');
            return this.gdprApiService.checkByToken(token).pipe(flatMap(showGDPRPage => {
                localStorage.setItem('email', showGDPRPage.email);
                if (showGDPRPage.showGDPRPage == false) {
                    return of(true);
                }
                else {
                    this.router.navigate(['/gdpr'], { queryParams: { returnUrl: state.url } });
                    return of(false);
                }
            }));
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }
}