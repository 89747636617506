import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { QuestionnairesGetAllByCompanyIdResponseItem } from "../api.services/models/questionnairesGetAllByCompanyIdResponseItem";
import { QuestionnairesGetAllByLeaderInFocusResponseItem } from "../api.services/models/questionnairesGetAllByLeaderInFocusResponseItem";
import { QuestionnairesApiService } from "../api.services/questionnaires.api.service";

@Component({
  selector: 'leaderInFocus-reports',
  templateUrl: './leaderInFocus-reports.component.html'
})
export class LeaderInFocuseReportsComponent implements OnInit {

  public companyId: string = "";
  public companyName: string = "";
  public name: string;
  public reports: QuestionnairesGetAllByLeaderInFocusResponseItem[] = [];
  public isDisabled: boolean = false;
  public toster: ToastrService;
  public createButtonTooltip: string = "Click to Create";

  public reportsDisplayedColumns: string[] = [
    'description',
    'numberOfRecipients',
    'numberOfAnswered',
    'numberOfNotAnswered',
    'insight',
    'report'];
  public reportsDataSource = new MatTableDataSource<QuestionnairesGetAllByLeaderInFocusResponseItem>(this.reports);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private questionnairesApiService: QuestionnairesApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.getQuestionnaires();
  }

  private async getQuestionnaires() {
    var questionnairesByLeaderInFocus = await this.questionnairesApiService.getQuestionnairesByLeaderInFocus();
    this.name = questionnairesByLeaderInFocus.name;
    this.reports = questionnairesByLeaderInFocus.items;
    this.reportsDataSource = new MatTableDataSource<QuestionnairesGetAllByLeaderInFocusResponseItem>(this.reports);
    this.reportsDataSource.paginator = this.paginator;
  }
}
