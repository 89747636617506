import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersCreateRequest } from "src/app/api.services/models/models";

@Component({
    selector: 'add.user.dialog',
    templateUrl: 'add.user.dialog.html',
})
export class AddUserDialog {

    public isActive: false;
    constructor(
        public dialogRef: MatDialogRef<AddUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data: UsersCreateRequest) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}