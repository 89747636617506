import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersApiService } from 'src/app/api.services/users.api.service';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: '[set-password]',
  templateUrl: './set.password.component.html'
})
export class SetPasswordComponent implements OnInit {
  public form: FormGroup;
  public userId: string;
  public token: string;
  public loading: boolean = false;
  public isActive: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private usersApiService: UsersApiService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private authorizationApiService: AuthorizationApiService
  ) { }

  async ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_=+?|<>])[A-Za-z0-9!@#$%^&*-_=+?|<>]{8,}$')]],
    });
  }

  change(): void {
    this.isActive = !this.isActive;
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    let password: string = this.form.get('password').value;

    var request = {
      userId: this.userId,
      token: this.token,
      password: password
    }

    this.loading = true;
    let result = await this.authorizationApiService.setPassword(request);
    this.loading = false;

    if (result.isSuccess) {
      this.router.navigate(['/login']);
    }
    else {
      this.toastrService.error(result.message)
    }

  }
}