import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ApiResult } from '../shared/models/api-result';

@Injectable({
    providedIn: 'root'
})
export class CommandHttpService {
    statusCodes: number[] = [400, 409];
    genericErrorMessage: string = 'An error occurred while processing your request';

    constructor(private http: HttpClient) { }

    async get(url: string): Promise<any> {
        try {
            let result: any = await this.http.get(url).toPromise();
            return result;
        }
        catch (error) {
            return this.extractError(error);
        }
    }

    async post<T>(url: string, requestBody?: any): Promise<ApiResult<T>> {
        try {
            let result: T = await this.http.post<T>(url, requestBody).toPromise();
            return ApiResult.success<T>(result);
        }
        catch (error) {
            return this.extractError(error);
        }
    }

    async put<T>(url: string, requestBody: any): Promise<ApiResult<T>> {
        try {
            let result: T = await this.http.put<T>(url, requestBody).toPromise();
            return ApiResult.success<T>(result);
        }
        catch (error) {
            return this.extractError(error);
        }
    }

    async delete<T>(url: string): Promise<ApiResult<T>> {
        try {
            let result: T = await this.http.delete<T>(url).toPromise();
            return ApiResult.success<T>(result);
        }
        catch (error) {
            return this.extractError(error);
        }
    }

    extractError<T>(httpError: HttpErrorResponse): ApiResult<T> {
        if (httpError.status == 403) {
            return ApiResult.error<T>("You don't have permissions to do this.");
        }
        let errorMessage: string = this.statusCodes.some(x => x == httpError.status)
            ? httpError.error
            : this.genericErrorMessage;

        return ApiResult.error<T>(errorMessage);
    }
}