import { NgModule } from "@angular/core";
import { TemplateListComponent } from "./template-list/template-list.component";
import { CreateTempateComponent } from "./create-template/create-tempate.component";
import { TemplatesRoutingModule } from "./templates-routing.module";
import { AngularMaterialModule } from "../shared/modules/angular-material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonModule } from '@angular/common';  
import { SharedModule } from "../shared/shared.module";
import { EditTemplateComponent } from "./edit-template/edit-template.component";

@NgModule({
    declarations: [
        TemplateListComponent,
        CreateTempateComponent,
        EditTemplateComponent
    ],
    imports: [
        CommonModule,
        TemplatesRoutingModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        SharedModule
    ],
    providers: []
})
export class TemplateModule { }
