import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'privacyPolicy',
  templateUrl: './privacyPolicy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
  ) { }

  async ngOnInit() {
  }
}
