import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";
import {
    RecipientGroupsGetByCompanyIdResponse,
    RecipientGroupsGetByCompanyIdResponseItem,
    RecipientGroupsCreateRequest,
    RecipientGroupsEditRequest,
} from "./models/models";

@Injectable({
    providedIn: 'root'
})
export class RecipientGroupsApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getRecipientGroupsByCompanyId(companyId: string): Promise<RecipientGroupsGetByCompanyIdResponseItem[]> {
        let params = new HttpParams({ fromObject: { companyId: companyId } });
        let url = `${this.apiUrls.recipientGroupsUrl}/all-by-companyid`;
        let apiResult: RecipientGroupsGetByCompanyIdResponse = await this.http.get<RecipientGroupsGetByCompanyIdResponse>(url, { params }).toPromise();

        return apiResult.items;
    }

    public async createRecipientGroup(companyId: string, name: string, order: number, isLeader: boolean, isBoss: boolean, minRecipients: number) {
        let url = `${this.apiUrls.recipientGroupsUrl}/create`;
        let requestBody: RecipientGroupsCreateRequest = { name: name, companyId: companyId, order: order, isLeader: isLeader, isBoss: isBoss, minRecipients: minRecipients};
        await this.commandHttp.post(url, requestBody);
    }

    public async editRecipientGroup(id: string, name: string, order: number, companyId: string, isLeader: boolean, isBoss: boolean, minRecipients: number) {
        let url = `${this.apiUrls.recipientGroupsUrl}/edit`;
        let requestBody: RecipientGroupsEditRequest = { id: id, name: name, order: order, companyId:  companyId, isLeader: isLeader, isBoss: isBoss, minRecipients: minRecipients};
        await this.commandHttp.post(url, requestBody);
    }

    public async deleteRecipientGroup(id: string) {
        let url = `${this.apiUrls.recipientGroupsUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }
}
