import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';
import { TemplateQuestionsApiService } from '../../api.services/template.questions.api.service';
import { RecipientGroupsGetByCompanyIdResponseItem, QuestionnairesCreateRequestArea, QuestionnairesCreateRequestRecipient, TemplateQuestionsGetByTemplateIdResponseArea, QuestionnairesCreateRequestLeaderInFocus } from '../../api.services/models/models';
import { RecipientGroupsApiService } from '../../api.services/recipient.groups.api.service';
import { QuestionnairesApiService } from '../../api.services/questionnaires.api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionPlanApiService } from 'src/app/api.services/subscription.plan.api.service';
import { StepOneComponent } from './step-one/step-one.component';
import { tickFormat } from '@swimlane/ngx-charts';
import { StepTwoComponent } from './step-two/step-two.component';
import { StepFourComponent } from './step-four/step-four.component';
import { StepThreeComponent } from './step-three/step-three.component';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-create-questionnaire',
    templateUrl: './create-questionnaire.component.html',
    styleUrls: ['./create-questionnaire.component.scss']
})
export class CreateQuestionnaireComponent implements OnInit {

    public canCreate: boolean = false;
    public recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[] | null = null;
    public templateId: string | null = null;
    public areas: TemplateQuestionsGetByTemplateIdResponseArea[] | null = null;
    public backFromStepTwo: boolean = false;
    public isApiRequestInProgress: boolean = false;
    public companyId: string | null = null;

    constructor(private formBuilder: FormBuilder,
        private templateQuestionsApiService: TemplateQuestionsApiService,
        private recipientGroupsApiService: RecipientGroupsApiService,
        private questionnairesApiService: QuestionnairesApiService,
        private router: Router,
        private subscriptionPlanApiService: SubscriptionPlanApiService,
        public toster: ToastrService) { }

    @ViewChild(StepOneComponent)
    stepOne!: StepOneComponent;

    @ViewChild(StepTwoComponent)
    stepTwo!: StepTwoComponent;

    @ViewChild(StepFourComponent)
    stepFour!: StepFourComponent;

    @ViewChild(StepThreeComponent)
    stepThree!: StepThreeComponent;

    async ngOnInit() {
        await this.canCreateQuestionnaire();
        if (!this.canCreate) {

            this.router.navigate([`/dashboard`]);
            return;
        }
    }


    public async goToAddQuestions($event: any) {
        this.recipientGroups = $event[0];
        this.templateId = $event[1];
        var isTemplateChanged = $event[3];
        this.companyId = $event[4];
        if (isTemplateChanged && this.templateId != null) {
            this.areas = $event[2];
        }
    }

    public backToInit($event: any) {
        this.backFromStepTwo = true;
    }

    public nextToAddRecipients($event: any) {

    }

    public async submit() {
        var basic = this.stepOne.formGroup.value;
        var areas = this.stepTwo.form.value.areas as QuestionnairesCreateRequestArea[];
        var i = 1;
        areas.forEach(element => {
            element.ratingValue = this.stepTwo.form.controls.ratingValue.value
            element.orderNumber = i;
            var j = 1;
            element.questions.forEach(question => {
                question.orderNumber = j;
                j++;
            });
            i++;
        });
        var recipientsAndDateGroup = this.stepThree.form.value;
        var leadersInFocus = recipientsAndDateGroup.leaderInFocus as QuestionnairesCreateRequestLeaderInFocus[];
        leadersInFocus.forEach(elem => {
            elem.id = Guid.create().toString();
            elem.recipients.forEach(el => {
                el.id = Guid.create().toString();
            });
        })
        const datePipe: DatePipe = new DatePipe('en-US');
        var recipientLimit = this.stepFour.form.value.recipientsLimit;

        var date = {
            title: basic.title,
            description: basic.description,
            companyId: basic.companyId,
            areas: areas,
            date: datePipe.transform(recipientsAndDateGroup.date, 'dd-MMM-yyyy HH:mm:ss'),
            leaderInFocusList: leadersInFocus,
            recipientGroupLimit: recipientLimit
        }
        this.isApiRequestInProgress = true;
        var response = await this.questionnairesApiService.create(date);
        this.isApiRequestInProgress = false;
        if (!response.isSuccess) {
            if (!response.data.isSuccess) {
                this.toster.error(response.data.message);
                return;
            }
            this.toster.error(response.message);
            return;
        }
        else {
            if (!response.data.isSuccess) {
                this.toster.error(response.data.message);
                return;
            }
            this.router.navigate([`/clients/${basic.companyId}/${basic.companyName}/questionnaires`]);
            this.toster.success("Successfully saved.");
        }
    }


    private async canCreateQuestionnaire() {
        this.canCreate = await this.subscriptionPlanApiService.canCreateQuestionnaire();
    }
}
