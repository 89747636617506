import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SurveysApiService } from "../api.services/surveys.api.service";
import { SurveysGetQuestionnaireResponse, SurveysGetQuestionnaireArea, SurveysGetQuestionnaireQuestion, SurveysAnswerQuestionnaireQuestionnaireAnswers } from "../api.services/models/models";
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialog } from "./confirmation.dialog";

@Component({
  selector: 'surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {
  public areaLength: number;
  public form: FormGroup;
  public areas: SurveysGetQuestionnaireArea[];
  public areasFormArray: FormArray;
  public token: string;
  public isApiRequestInProgress: boolean;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private surveysApiService: SurveysApiService,
    private toastr: ToastrService,
    private router: Router,
    private confirmationDialogNext: MatDialog,
    private confirmationDialogBack: MatDialog) { }

  public async ngOnInit(): Promise<void> {
    this.token = this.route.snapshot.paramMap.get('token');
    var surveys = this.route.snapshot.data.surveys as SurveysGetQuestionnaireResponse;
    if (surveys.areas == null) {
      this.router.navigateByUrl('/message', { state: { message: "Uh-Oh :( <br/> This token is no longer available." } });
      return;
    }
    else {
      var reciprnt = surveys.recipient;
      this.areas = surveys.areas;
      this.areaLength = this.areas.length;
      this.areasFormArray = this.initArea(this.areas);
      this.form = this.formBuilder.group(
        {
          title: [surveys.title],
          recipientGroup: [reciprnt.recipientGroup],
          numQuestion: [surveys.numberOfQuestions],
          areas: this.areasFormArray
        })
    }
  }

  public initArea(areas: SurveysGetQuestionnaireArea[]) {
    var formArray = new FormArray([]);
    for (const area of areas) {
      var areaGroup = this.formBuilder.group({
        id: [area.id],
        title: [area.title],
        questions: this.initQuestions(area.questions),
      })
      formArray.push(areaGroup);
    }
    return formArray;
  }
  public initQuestions(questions: SurveysGetQuestionnaireQuestion[]) {
    var formArray = new FormArray([]);
    for (const question of questions) {
      var questionGroup = this.formBuilder.group({
        id: [question.id],
        title: [question.title],
        description: [question.description],
        questionType: [question.questionType],
        ratingValue: [question.ratingValue],
        answer: [],
        rating: []
      })
      formArray.push(questionGroup);
    }
    return formArray;
  }

  public onRatingChanged(rating, i, j) {
    var area = this.form.get('areas') as FormArray;
    var question = area.controls[i].get('questions') as FormArray;
    var control = question.controls[j].get('rating') as FormControl;
    control.setValue(rating);
    var control = question.controls[j].get('answer') as FormControl;
    control.setValue(rating);

  }

  public checkIfAllAnswer(index) {
    var count = 0;
    var areas = this.form.get('areas') as FormArray;
    var questions = areas.controls[index].get('questions') as FormArray;
    questions.controls.forEach(element => {
      if (element.get('answer').value === null || element.get('answer').value.toString().trim() === '' || element.get('answer').value.toString().trim() === "" || element.get('answer').value === undefined) {
        count++;
      }
    });
    return count;
  }

  public checkIfAllAnswerNext(index) {
    var count = this.checkIfAllAnswer(index);
    if (count === 0) {
      this.stepper.next();
    }
    else{
       this.openConfirmationDialogNext();
    }
  }

  public checkIfAllAnswerBack(index) {
    var count = this.checkIfAllAnswer(index);
    if (count === 0) {
      this.stepper.previous();
    }
    else{
      this.openConfirmationDialogBack();
    }
  }

    openConfirmationDialogNext(): void {
        const dialogRef = this.confirmationDialogNext.open(ConfirmationDialog, {
            width: '600px',
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                if(result == true){
                   this.stepper.next();
                }
            }
        });
    }

    openConfirmationDialogBack(): void {
      const dialogRef = this.confirmationDialogBack.open(ConfirmationDialog, {
          width: '600px',
          autoFocus: false
      });

      dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
              if(result == true){
                 this.stepper.previous();
              }
          }
      });
  }

  public async onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isApiRequestInProgress = true;
    var count = 0;
    var answerList: SurveysAnswerQuestionnaireQuestionnaireAnswers[] = [];
    var area = this.form.get('areas') as FormArray;
    var areaLenght = area.controls.length;
    for (let index = 0; index < areaLenght; index++) {
      var question = area.controls[index].get('questions') as FormArray;
      question.controls.forEach(element => {
        var value = element.get('answer').value;
        if (value === null || value.toString().trim() === '' || value.toString().trim() === "" || value === undefined) {
          count++;
        }
        var data: SurveysAnswerQuestionnaireQuestionnaireAnswers = {
          value: value != null ? value.toString() : null,
          questionId: element.get('id').value,
        }
        answerList.push(data);
      });
    }

    var response = await this.surveysApiService.answerQuestionnaire(this.token, answerList);
    if (!response.isSuccess) {
      this.toastr.error(response.message);
      return;
    }

    this.isApiRequestInProgress = false;
    this.toastr.success("You have successfully answered all questions.");
    this.router.navigateByUrl('/message', { state: { message: "Thank you for <br/> the feedback!" } });
  }

}


