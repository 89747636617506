import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";
import { SurveysGetQuestionnaireResponse, SurveysAnswerQuestionnaireQuestionnaireAnswers, SurveysAnswerQuestionnaireResponse } from "./models/models";


@Injectable({
    providedIn: 'root'
})
export class SurveysApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getSurveys(token: string): Promise<SurveysGetQuestionnaireResponse> {
        let params = new HttpParams({ fromObject: { token: token } });
        let url = `${this.apiUrls.surveysUrl}/get-by-token`;
        let apiResult: SurveysGetQuestionnaireResponse = await this.http.get<SurveysGetQuestionnaireResponse>(url, {params}).toPromise();
        return apiResult;
    }

    public async answerQuestionnaire(token: string, answerList: SurveysAnswerQuestionnaireQuestionnaireAnswers[]): Promise<ApiResult<SurveysAnswerQuestionnaireResponse>> {
        var data = {
            token: token,
            answers: answerList
        }
        let url = `${this.apiUrls.surveysUrl}/answer-questionnaire`;
        var respons = await this.commandHttp.post(url, data);
        return respons;
    }
}