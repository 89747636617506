import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from './shared/modules/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { LeaderInFocusRoutingModule } from './leaderInFocus-routing.module';
import { LeaderInFocuseReportsComponent } from './leaderInFocus-reports/leaderInFocus-reports.component';
import { CommonModule } from '@angular/common';
import { CommentsAndActionsComponent } from './leaderInFocus/commentsAndActions/commentsAndActions.component';
import { CommentsComponent } from './leaderInFocus/comments/comments.component';
import { TemplateModule } from './templates/templates.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ActionComponent } from './leaderInFocus/actions/actions.component';
import { PersonalNotesComponent } from './leaderInFocus/personal-notes/personal-notes.component';
import { LeaderInFocusInsightComponent } from './leaderInFocus-insight/leaderInFocus-insight.component';
import { RealityCheckIndexComponent } from './leaderInFocus-insight/realityCheck-index/realityCheck-index.component';
import { RealityCheckIndexPerGroupComponent } from './leaderInFocus-insight/realityCheck-index-per-group/realityCheck-index-per-group.component';
import { LeaderInFocusInsightActionsComponent } from './leaderInFocus-insight/leaderInFocus-insight-actions/leaderInFocus-insight-actions.component';
import { StepperComponent } from './shared/stepper/stepper.component';
import { CardOrangeComponent } from './shared/card-orange/card-orange.component';
import { CardGreenComponent } from './shared/card-green/card-green.component';
import { AveragePerAreaComponent } from './leaderInFocus-insight/average-per-area/average-per-area.component';
import { NgxPieChartZeroMarginDirective } from './shared/directive/pie-chart.directive';

@NgModule({
  declarations: [
    LeaderInFocuseReportsComponent,
    CommentsAndActionsComponent,
    CommentsComponent,
    ActionComponent,
    PersonalNotesComponent,
    LeaderInFocusInsightComponent,
    RealityCheckIndexComponent,
    RealityCheckIndexPerGroupComponent,
    LeaderInFocusInsightActionsComponent,
    StepperComponent,
    CardGreenComponent,
    CardOrangeComponent,
    AveragePerAreaComponent,
    NgxPieChartZeroMarginDirective
  ],
  imports: [
    LeaderInFocusRoutingModule,
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedModule,
    TemplateModule,
    SharedModule,
    NgxChartsModule,
  ]
})
export class LeaderInFocusModule { }
