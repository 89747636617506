import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { TenantsApiService } from 'src/app/api.services/tenants.api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TenantsGetAllResponseItem } from 'src/app/api.services/models/models';
import { AddTenantDialog } from '../add-tenent/add.tenant.dialog';
import { ToastrService } from 'ngx-toastr';
import { EditTenantDialog } from '../edit-tenant/edit.tenant.dialog';
import { DeleteTenantDialog } from '../delete-tenant/delete.tenant.dialog';
import { ChangeEmailServicePasswordDialog } from '../change-email-service-password/change.email.service.password.dialog';
import { Router } from '@angular/router';

@Component({
    templateUrl: './tenant.list.component.html'
})
export class TenantListComponent  implements OnInit{

    public tenants: TenantsGetAllResponseItem[] = [];
    tenantDisplayedColumns: string[] = [
        'name',
        'email',
        'numberOfAdmins',
        'numberOfReports',
        'actions'
    ];
    tenantDataSource = new MatTableDataSource<TenantsGetAllResponseItem>(this.tenants);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private tenantsApiService: TenantsApiService,
        private toastrService : ToastrService,
        private openDialog: MatDialog,
        private router: Router
    ) { }

    public async ngOnInit() {
        await this.getTenants();
    }

    private async getTenants(){
        this.tenants = await this.tenantsApiService.getAllTenants();
        this.tenantDataSource = new MatTableDataSource<TenantsGetAllResponseItem>(this.tenants);
        this.tenantDataSource.paginator = this.paginator;
    }

    public redirectToAdminsPage(element: TenantsGetAllResponseItem){
        this.router.navigate([`/admin/tenants/${element.id}/admin-users`]);
    }

    public redirectToSubscriptionPlansPage(element: TenantsGetAllResponseItem){
        this.router.navigate([`/admin/tenants/${element.id}/subscription-plans`]);
    }

    public openDialogAddTenant(){
            const dialogRef = this.openDialog.open(AddTenantDialog, {
                width: '600px',
                data: {   name: '',
                    email: '',
                    username: '',
                    password: '',
                    smtpHost: '',
                    smtpPort: 0,
                    enableSsl: false
                }
            });
    
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    var apiRequestResult = await this.tenantsApiService.addTenant(result);
                    if(apiRequestResult.isSuccess){
                        this.toastrService.success("Tenant was successfully saved.");
                        this.getTenants();
                    }
                    else {
                        this.toastrService.error("Tenant was not saved.");
                    }
                }
            });   
        }

        public async openDialogEditTenant(element: TenantsGetAllResponseItem){
            var dataElement = await this.tenantsApiService.getTenantById(element.id);
            const dialogRef = this.openDialog.open(EditTenantDialog, {
                width: '600px',
                data: {  
                    id: dataElement.id,
                    name: dataElement.name,
                    email: dataElement.email,
                    username: dataElement.username,
                    smtpHost: dataElement.smtpHost,
                    smtpPort: dataElement.smtpPort,
                    enableSsl: dataElement.enableSsl
                }
            });
    
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    var apiRequestResult = await this.tenantsApiService.editTenant(result);
                    if(apiRequestResult.isSuccess){
                        this.toastrService.success("Tenant was successfully edited.");
                        this.getTenants();
                    }
                    else {
                        this.toastrService.error("Tenant was not edited.");
                    }
                }
            });


        
    }
    public async openDialogDeleteTenant(element: TenantsGetAllResponseItem){
        const dialogRef = this.openDialog.open(DeleteTenantDialog, {
            width: '600px',
            data: {  
                id: element.id,
                name: element.name
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResult = await this.tenantsApiService.deleteTenant(result.id);
                if(apiRequestResult.isSuccess){
                    this.toastrService.success("Tenant was successfully deleted.");
                    this.getTenants();
                }
                else {
                    this.toastrService.error("Tenant was not deleted.");
                }
            }
        });
    }

    public async openDialogChangePassword(element: TenantsGetAllResponseItem){
        const dialogRef = this.openDialog.open(ChangeEmailServicePasswordDialog, {
            width: '600px',
            data: {  
                id: element.id,
                password: ''
            }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var apiRequestResult = await this.tenantsApiService.changePassword(result);
                if(apiRequestResult.isSuccess){
                    this.toastrService.success("Email service password is successfully changed.");
                    this.getTenants();
                }
                else {
                    this.toastrService.error("Email service password is not changed.");
                }
            }
        });
    }
}