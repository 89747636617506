import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'custom-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

    private _activeStep = 0;
    @Input() set activeStep(value: number) {
        if (value) {
            this._activeStep = value;
        }
    }
    get activeStep(): number {
        return this._activeStep;
    }
    constructor(
    ) { }

    ngOnInit(): void {
    }
}
