import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'card-green',
    templateUrl: './card-green.component.html',
    styleUrls: ['./card-green.component.scss']
})
export class CardGreenComponent implements OnInit {

    private _title = "";
    @Input() set title(value: string) {
        if (value) {
            this._title = value;
        }
    }
    get title(): string {
        return this._title;
    }
    constructor(
    ) { }

    ngOnInit(): void {
    }
}
