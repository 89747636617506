import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RecipientGroupsCreateRequest } from "../api.services/models/models";

@Component({
    selector: 'add.recipient.group.dialog',
    templateUrl: 'add.recipient.group.dialog.html',
})
export class AddRecipientGroupDialog {

    constructor(
        public dialogRef: MatDialogRef<AddRecipientGroupDialog>,
        @Inject(MAT_DIALOG_DATA) public data: RecipientGroupsCreateRequest) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}