import { Component, OnInit, ViewChild } from "@angular/core";


@Component({
    selector: 'commentsAndActions',
    templateUrl: './commentsAndActions.component.html'
})
export class CommentsAndActionsComponent implements OnInit {
    constructor(
    ) { }


    ngOnInit(): void {
    }


}