import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { ApiUrls } from "./api-urls";
import {
    TemplatesGetAllResponseItem,
    TemplatesGetAllResponse,
    TemplatesCreateRequest,
    TemplatesCreateResponse,
    TemplatesGetByIdResponse,
    TemplatesEditRequest,
    TemplatesEditResponse
} from "./models/models";
import { ApiResult } from "../shared/models/api-result";


@Injectable({
    providedIn: 'root'
})
export class TemplatesApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getTemplates(): Promise<TemplatesGetAllResponseItem[]> {
        let url = `${this.apiUrls.templatesUrl}/all`;
        let apiResult: TemplatesGetAllResponse = await this.http.get<TemplatesGetAllResponse>(url).toPromise();
        return apiResult.items;
    }

    public async getTemplateById(templateId: string): Promise<TemplatesGetByIdResponse> {
        let params = new HttpParams({ fromObject: { templateId: templateId } });
        let url = `${this.apiUrls.templatesUrl}/by-template-id`;
        let apiResult: TemplatesGetByIdResponse = await this.http.get<TemplatesGetByIdResponse>(url, {params}).toPromise();
        return apiResult;
    }
    

    public async createTemplates(template: TemplatesCreateRequest): Promise<ApiResult<TemplatesCreateResponse>> {
        let url = `${this.apiUrls.templatesUrl}/create`;
        var respons = await this.commandHttp.post<TemplatesCreateResponse>(url, template);
        return respons;
    }

    public async editTemplates(template: TemplatesEditRequest): Promise<ApiResult<TemplatesEditResponse>> {
        let url = `${this.apiUrls.templatesUrl}/edit`;
        var respons = await this.commandHttp.post<TemplatesEditResponse>(url, template);
        return respons;
    }

    public async deleteTemplate(id: string) {
        let url = `${this.apiUrls.templatesUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }
}