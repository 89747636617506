import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { TenantsGetAllResponseItem, TenantsGetAllResponse, TenantsCreateRequest, TenantsEditRequest, TenantsGetByIdResponse, TenantsEditPasswordRequest } from "./models/models";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";

@Injectable({
    providedIn: 'root'
})
export class TenantsApiService{
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getAllTenants(): Promise<TenantsGetAllResponseItem[]> {
        let url = `${this.apiUrls.tenantUrl}/all`;
        let apiResult: TenantsGetAllResponse = await this.http.get<TenantsGetAllResponse>(url).toPromise();

        return apiResult.items;
    }

    public async addTenant(tenant: TenantsCreateRequest)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.tenantUrl}/create`;
        let requestBody = {
            name: tenant.name,
            email: tenant.email,
            username: tenant.username,
            password: tenant.password,
            smtpHost: tenant.smtpHost, 
            smtpPort: tenant.smtpPort as number, 
            enableSsl: tenant.enableSsl
        }
        return await this.commandHttp.post(url, requestBody);
    }

    public async getTenantById(id): Promise<TenantsGetByIdResponse> {
        let params = new HttpParams({ fromObject: { id: id } });
        let url = `${this.apiUrls.tenantUrl}/tenant`;
        let apiResult: TenantsGetByIdResponse = await this.http.get<TenantsGetByIdResponse>(url, {params}).toPromise();

        return apiResult;
    }

    public async editTenant(tenant: TenantsEditRequest)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.tenantUrl}/edit`;
        return await this.commandHttp.post(url, tenant);
    }

    public async deleteTenant(id: string)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.tenantUrl}/delete`;
        var body = {
            id: id
        }
        return await this.commandHttp.post(url, body);
    }

    public async changePassword(request: TenantsEditPasswordRequest)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.tenantUrl}/edit-password`;
        return await this.commandHttp.post(url, request);
    }
}