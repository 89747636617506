import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';
import { AuthenticationLeaderInFocusService } from '../authenticationLeaderInFocus.service';

@Component({
  selector: '[leaderInFocuse-emailRequest]',
  templateUrl: './leaderInFocuse-emailRequest.component.html'
})
export class LeaderInFocuseEmailRequestComponent implements OnInit {
  public loginForm: FormGroup;
  public loginInvalid: boolean;
  public message = new BehaviorSubject<string>(null);
  public returnUrl: string
  public loading: boolean = false;
  public tenant: string ="";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authorizationApiService: AuthorizationApiService,
    private authService: AuthenticationLeaderInFocusService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,

  ) { }

  async ngOnInit() {
    this.tenant = this.activatedRoute.snapshot.paramMap.get('tenantId');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  async onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    let email: string = this.loginForm.get('email').value;

    this.loading = true;
    let result = (await this.authorizationApiService.emailRequestLeaderInFocus(email, this.tenant));
    this.loading = false;
    
    if (result.isSuccess) {
      localStorage.setItem('email', email);
			this.router.navigate([`/leaderInFocus/${this.tenant}/login`]);
    }
    else {
      this.toastrService.error(result.message)
    }

  }
}