import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UsersEditRequest } from "src/app/api.services/models/models";

@Component({
    selector: 'edit.admin.dialog',
    templateUrl: 'edit.admin.dialog.html',
})
export class EditAdminDialog{


    constructor(
        public dialogRef: MatDialogRef<EditAdminDialog>,
        @Inject(MAT_DIALOG_DATA) public data: UsersEditRequest) { }
    
    onNoClick(): void {
        this.dialogRef.close();
    }
}