import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { ApiUrls } from "./api-urls";
import { ReportGenerateReportDataResponse } from "./models/models";

@Injectable({
    providedIn: 'root'
})
export class ReportApiService {
    constructor(
        private http: HttpClient,
        private apiUrls: ApiUrls
    ) { }

    public async getReportGenerateData(questionnaireId: string): Promise<ReportGenerateReportDataResponse> {
        let params = new HttpParams({ fromObject: { questionnaireId: questionnaireId } });
        let url = `${this.apiUrls.reportUrl}/generate-report-data`;
        let apiResult: ReportGenerateReportDataResponse = await this.http.get<ReportGenerateReportDataResponse>(url, {params}).toPromise();
        return apiResult;
    }
}