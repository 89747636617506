import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { SubscriptionPlansCanCreateQuestionnaireResponse,
     SubscriptionPlansCreateRequest,
     SubscriptionPlansDeleteRequest,
     SubscriptionPlansGetAllByTenantIdResponse, 
    SubscriptionPlansGetAllByTenantIdResponseItem,
 } from "./models/models";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPlanApiService {
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public async getAllByTenantId(tenantId: string): Promise<SubscriptionPlansGetAllByTenantIdResponseItem[]> {
        let params = new HttpParams({ fromObject: { tenantId: tenantId } });
        let url = `${this.apiUrls.subscriptionPlanUrl}/all-by-tenantid`;
        let apiResult: SubscriptionPlansGetAllByTenantIdResponse = await this.http.get<SubscriptionPlansGetAllByTenantIdResponse>(url, {params}).toPromise();
        return apiResult.items;
    }

    public async canCreateQuestionnaire(): Promise<boolean> {
        let url = `${this.apiUrls.subscriptionPlanUrl}/is-allowed`;
        let apiResult: SubscriptionPlansCanCreateQuestionnaireResponse = await this.http.get<SubscriptionPlansCanCreateQuestionnaireResponse>(url).toPromise();
        return apiResult.flag ?? false;
    }

    public async create(subPlan: SubscriptionPlansCreateRequest)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.subscriptionPlanUrl}/create`;
        let requestBody = {
            tenantId: subPlan.tenantId,
            numberOfReports: subPlan.numberOfReports,
            description: subPlan.description,
            cost: subPlan.cost
        }
        return await this.commandHttp.post(url, requestBody);
    }

    public async delete(subPlanId: string)  : Promise <ApiResult<void>>{
        let url = `${this.apiUrls.subscriptionPlanUrl}/delete`;
        let requestBody = {
            id: subPlanId
        }
        return await this.commandHttp.post(url, requestBody);
    }
}