import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'generalConditions',
  templateUrl: './generalConditions.component.html'
})
export class GeneralConditionsComponent implements OnInit {

  constructor(
  ) { }

  async ngOnInit() {
  }
}
