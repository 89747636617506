import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationApiService } from 'src/app/api.services/authorization.api.service';

@Component({
  selector: '[login]',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loginInvalid: boolean;
  public message = new BehaviorSubject<string>(null);
  public returnUrl: string
  public loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationApiService: AuthorizationApiService,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required]
    });
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  async onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    let username: string = this.loginForm.get('username').value;
    let password: string = this.loginForm.get('password').value;

    this.loading = true;
    let result = (await this.authenticationApiService.login(username, password));
    this.loading = false;
    
    if (result.isSuccess) {
      this.authService.setAuth(result.data);
      var url = this.returnUrl == "/" ? "/dashboard" : this.returnUrl;
			this.router.navigate([url]);
    }
    else {
      this.toastrService.error(result.message)
    }

  }
}