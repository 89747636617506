import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionInfoDialog } from "./actionInfo.dialog";

@Component({
    selector: 'leaderInFocus-insight-actions',
    templateUrl: './leaderInFocus-insight-actions.component.html',
    styleUrls: ['./leaderInFocus-insight-actions.component.scss']
})
export class LeaderInFocusInsightActionsComponent implements OnInit {

    private _actionLevel = "First";
    @Input() set actionLevel(value: string) {
        if (value != "") {
            this._actionLevel = value;
        }
    }
    get actionLevel(): string {
        return this._actionLevel;
    }

    private _numberOfActions: number;
    public isLeaderInFocus = false;
    @Input() set numberOfActions(value: number) {
        this._numberOfActions = value;
    }
    get numberOfActions(): number {
        return this._numberOfActions;
    }
    constructor(
        private infoDialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        var leaderInFocusId = this.route.snapshot.paramMap.get("leaderInFocusId");
        if(leaderInFocusId == null){
            this.isLeaderInFocus = true;
        }
    }

    public openInfoDialog(): void {
        const elemRect = (event.target as HTMLElement).getBoundingClientRect()
        const dialogRef = this.infoDialog.open(ActionInfoDialog, {
            width: '300px',
            height: '300px',
            autoFocus: false,
            position: {
                top: elemRect.bottom + 20 + "px",
                left: elemRect.right - 250 + "px"
            }
        });
    }

    public openActionLink(){
     
    }
}