import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'card-orange',
    templateUrl: './card-orange.component.html',
    styleUrls: ['./card-orange.component.scss']
})
export class CardOrangeComponent implements OnInit {

    private _title = "";
    @Input() set title(value: string) {
        if (value) {
            this._title = value;
        }
    }
    get title(): string {
        return this._title;
    }
    constructor(
    ) { }

    ngOnInit(): void {
    }
}
