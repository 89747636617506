import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Guid } from "guid-typescript";
import { ToastrService } from "ngx-toastr";
import { LeaderInFocusActionApiService } from "src/app/api.services/leaderInFocus.action.api.service";
import { LeaderInFocusActionsGetResponseItem } from "src/app/api.services/models/leaderInFocusActionsGetResponseItem";
import { QuestionnaireApiServicesIServicesEnumsActionLevelEnum } from "src/app/api.services/models/questionnaireApiServicesIServicesEnumsActionLevelEnum";
import { AuthenticationLeaderInFocusService } from "src/app/authorization/authenticationLeaderInFocus.service";
import { DeleteActionDialog } from "./delete.action.dialog";



@Component({
  selector: 'action',
  templateUrl: './actions.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {

  public questionnaireId: string = "";
  public leaderInFocusId: string = "";
  public actionLevel: QuestionnaireApiServicesIServicesEnumsActionLevelEnum;
  public actions: LeaderInFocusActionsGetResponseItem[] = [];
  public leaderInFocusAction: LeaderInFocusAction[] = [];
  public actionsColumns: string[] = [
    'done',
    'message',
    'showMore',
    'date',
    'completeDate',
    'actions'];
  public actionsDataSource = new MatTableDataSource<LeaderInFocusActionsGetResponseItem>(this.leaderInFocusAction);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private leaderInFocusActionApiService: LeaderInFocusActionApiService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private deleteActionDialog: MatDialog,
    private authenticationLeaderInFocus: AuthenticationLeaderInFocusService
  ) { }

  async ngOnInit(): Promise<void> {
    this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
    this.leaderInFocusId = this.route.snapshot.paramMap.get("leaderInFocusId");
    if (this.leaderInFocusId == null) {
      var auth = this.authenticationLeaderInFocus.getAuth();
      this.leaderInFocusId = auth.userId;
    }
    await this.getActions();
  }

  public addActionAction() {
    var action = new LeaderInFocusAction();
    action.message = "";
    action.isAdded = true;
    action.isEdit = true;
    action.date = new Date(Date.now());
    action.readMore = false;
    action.readLess = false;
    action.id = Guid.create().toString();
    action.done = false;
    action.completeDate = null;
    this.leaderInFocusAction.unshift(action);
    this.actionsDataSource = new MatTableDataSource<LeaderInFocusAction>(this.leaderInFocusAction);
    this.actionsDataSource.paginator = this.paginator;
  }

  public onEdit(action: any): void {
    var leaderInFocusActionIndex = this.leaderInFocusAction.findIndex(x => x.id == action.id);
    if (leaderInFocusActionIndex != -1) {
      this.leaderInFocusAction[leaderInFocusActionIndex].isEdit = true;
    }
  }

  public onSave(action: any): void {
    if (action.isAdded) {
      this.addAction(action.message, action.done);
    }
    else {
      this.editAction(action.id, action.message, action.done);
    }
  }

  public onRemove(action: any): void {
    if (action.isAdded) {
      this.leaderInFocusAction.shift();
      this.actionsDataSource = new MatTableDataSource<LeaderInFocusAction>(this.leaderInFocusAction);
      this.actionsDataSource.paginator = this.paginator;
    }
    else if (action.isEdit && !action.isAdded) {
      var leaderInFocusCommentIndex = this.leaderInFocusAction.findIndex(x => x.id == action.id);
      if (leaderInFocusCommentIndex != -1) {
        this.leaderInFocusAction[leaderInFocusCommentIndex].isEdit = false;
      }
    }
    else if (!action.isAdded && !action.isEdit) {
      const dialogRef = this.deleteActionDialog.open(DeleteActionDialog, {
        width: '600px',
        data: { id: action.id }
      });
  
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          await this.deleteAction(result.id);
        }
      });
    
    }
  }



  public showMore(action) {
    action.readLess = true;
    action.readMore = false;
  }

  public showLess(action) {
    action.readLess = false;
    action.readMore = true;
  }

  public onCheck(action) {
    action.done = !action.done;
    if (!action.isEdit) {
      this.checkAction(action.id, action.done);
    }
  }

  private async getActions() {
    this.leaderInFocusAction = [];
    var actionsResponse = await this.leaderInFocusActionApiService.getAction(this.questionnaireId);

    this.actions = actionsResponse.items;
    this.actionLevel = actionsResponse.actionLevel;

    this.actions.forEach(element => {
      var action = new LeaderInFocusAction();
      action.date = element.createdOn;
      action.id = element.id;
      action.message = element.message;
      action.readMore = element.message.length > 100;
      action.readLess = false;
      action.isEdit = false;
      action.isAdded = false;
      action.completeDate = element.completedDate;
      action.done = element.done
      this.leaderInFocusAction.push(action);
    });

    this.actionsDataSource = new MatTableDataSource<LeaderInFocusAction>(this.leaderInFocusAction);
    this.actionsDataSource.paginator = this.paginator;
  }

  private async editAction(id: string, message: string, done: boolean) {
    await this.leaderInFocusActionApiService.editAction(id, message, done);
    await this.getActions();
  }

  private async addAction(message: string, done: boolean) {
    await this.leaderInFocusActionApiService.addAction(this.leaderInFocusId, this.questionnaireId, message, done);
    await this.getActions();
  }

  private async deleteAction(id: string) {
    await this.leaderInFocusActionApiService.deleteAction(id);
    await this.getActions();
  }


  private async checkAction(id: string, done: boolean) {
    await this.leaderInFocusActionApiService.checkDone(id, done);
    await this.getActions();
   
  }


}


class LeaderInFocusAction {
  id?: string;
  message?: string;
  readMore: boolean;
  readLess: boolean;
  date?: Date;
  done?: boolean;
  isEdit: boolean;
  isAdded: boolean;
  completeDate?: Date;
}