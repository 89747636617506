import { OnInit, Component, Output, EventEmitter, Input } from "@angular/core";
import { ControlContainer, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';
import { CompaniesApiService } from "src/app/api.services/companies.api.service";
import { TemplatesGetAllResponseItem, CompaniesGetAllListResponseItem, RecipientGroupsGetByCompanyIdResponseItem, TemplateQuestionsGetByTemplateIdResponseArea } from "src/app/api.services/models/models";
import { RecipientGroupsApiService } from "src/app/api.services/recipient.groups.api.service";
import { TemplateQuestionsApiService } from "src/app/api.services/template.questions.api.service";
import { TemplatesApiService } from "src/app/api.services/templates.api.service";

@Component({
    selector: 'step-one',
    templateUrl: './step-one.component.html'
})

export class StepOneComponent implements OnInit {
    @Input() stepper: MatStepper;
    @Input() set backFromStepTwo(value: boolean) {
        if (value) {
            this.isTemplateChanged = false;
        }
        this._backFromStepTwo = value;
    }
    get backFromStepTwo(): boolean {
        return this._backFromStepTwo;
    }
    public _backFromStepTwo: boolean;
    @Output() nextStep = new EventEmitter<any>();

    public companies: CompaniesGetAllListResponseItem[];
    public templates: TemplatesGetAllResponseItem[];
    public isDataInitialized: boolean = false;
    public formGroup: FormGroup = new FormGroup({});
    public recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[] | null = null;
    public templateId: string;
    public isTemplateChanged: boolean = false;
    public areas: TemplateQuestionsGetByTemplateIdResponseArea[] | null = null;
    public companyId: string| null = null;

    constructor(public controlContainer: ControlContainer,
        private companiesApiService: CompaniesApiService,
        private templatesApiService: TemplatesApiService,
        private formBuilder: FormBuilder,
        private recipientGroupsApiService: RecipientGroupsApiService,
        private templateQuestionsApiService: TemplateQuestionsApiService,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.populateForm();
        this.companies = await this.companiesApiService.getCompaniesList();
        this.templates = await this.templatesApiService.getTemplates();
        this.isDataInitialized = true;
    }

    public populateForm() {
        this.formGroup = this.formBuilder.group({
            companyId: [, Validators.required],
            companyName: [],
            title: [, Validators.required],
            templateId: [],
            description: []
        })
    }

    public async next() {
        if (!this.formGroup.valid) {
            return;
        }
        if (this.isTemplateChanged) {
            this.areas = await this.templateQuestionsApiService.getQuestionsByTemplateId(this.templateId);
        }
        this.nextStep.emit([this.recipientGroups, this.templateId, this.areas, this.isTemplateChanged, this.companyId ])
        this.stepper.next();
    }

    public onSelectTemplate(event: EventListener) {
        this.templateId = this.formGroup.get('templateId').value;
        if (this.formGroup.get('title').value == null) {
            var templateName = this.templates.find(x => x.id == this.templateId).title;
            this.formGroup.get('title').setValue(templateName);
        }
        this.isTemplateChanged = true;
    }

    public onCompanyChange(event) {
        var companyName = this.companies.find(x => x.id == event).name;
        this.formGroup.get('companyName').setValue(companyName);
        this.companyId = event;
        this.getRecipientsGroups(event);
    }

    public async getRecipientsGroups(companyId) {
        this.recipientGroups = await this.recipientGroupsApiService.getRecipientGroupsByCompanyId(companyId);
    }
}
