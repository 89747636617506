import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../authorization/guards/auth-guard.service";
import { NgModule } from "@angular/core";
import { TenantListComponent } from "./tenant/tenant-list/tenant.list.component";
import { UserListComponent } from "./users/user-list/user.list.component";
import { AdminUserListComponent } from "./admin-users/admin-list/admin.user.list.component";
import { SubscriptionPlansListComponent } from "./subscription-plans/subscription-plans-list/subscription.plans.list.component";

export class AdminModule { }

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: 'tenants', component: TenantListComponent },
            { path: 'tenants/:tenantId/admin-users', component: AdminUserListComponent },
            { path: 'users', component: UserListComponent },
            { path: 'tenants/:tenantId/subscription-plans', component: SubscriptionPlansListComponent },
        ],
    }
];


@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
    providers: [
    ]
})
export class AdminRoutingModule { }