import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";
import { 
    QuestionnairesCreateRequest, 
    QuestionnairesCreateResponse, 
    QuestionnairesGetAllByCompanyIdResponseItem, 
    QuestionnairesGetAllByCompanyIdResponse,
    QuestionnairesGetAllByLeaderInFocusResponseItem,
    QuestionnairesGetAllByLeaderInFocusResponse,
    QuestionnairesGetLatestByLeaderInFocusResponse, 
    QuestionnairesImportRecipientsResponse,
    QuestionnairesImportRecipientsResponseItem,
    QuestionnairesDownloadTemplateResponse} from "./models/models";

@Injectable({
    providedIn: 'root'
})
export class QuestionnairesApiService{
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }
   
    public async create(questionnaire: QuestionnairesCreateRequest) : Promise<ApiResult<QuestionnairesCreateResponse>>{
        let url = `${this.apiUrls.questionnairesUrl}/create`;
        var respons = await this.commandHttp.post<QuestionnairesCreateResponse>(url, questionnaire);
        return respons;
    }

    public async getQuestionnairesByCompanyId(companyId: string): Promise<QuestionnairesGetAllByCompanyIdResponseItem[]> {
        let params = new HttpParams({ fromObject: { companyId: companyId } });
        let url = `${this.apiUrls.questionnairesUrl}/all-by-companyid`;
        let apiResult: QuestionnairesGetAllByCompanyIdResponse = await this.http.get<QuestionnairesGetAllByCompanyIdResponse>(url, { params }).toPromise();

        return apiResult.items;
    }

    public async getQuestionnairesByLeaderInFocus(): Promise<QuestionnairesGetAllByLeaderInFocusResponse> {
        let url = `${this.apiUrls.questionnairesUrl}/all-by-leader-in-focus`;
        let apiResult: QuestionnairesGetAllByLeaderInFocusResponse = await this.http.get<QuestionnairesGetAllByLeaderInFocusResponse>(url).toPromise();

        return apiResult;
    }

    public async deleteQuestionnaire(id: string) {
        let url = `${this.apiUrls.questionnairesUrl}/delete`;
        let requestBody = { id: id };
        await this.commandHttp.post(url, requestBody);
    }

    public async latest(): Promise<string>{
        let url = `${this.apiUrls.questionnairesUrl}/latest`;
        let apiResult: QuestionnairesGetLatestByLeaderInFocusResponse = await this.http.get<QuestionnairesGetLatestByLeaderInFocusResponse>(url).toPromise();

        return apiResult.id;
    }

    public async importExcel(content: string, companyId: string) : Promise<ApiResult<QuestionnairesImportRecipientsResponse>> {
        let url = `${this.apiUrls.questionnairesUrl}/import-recipients`;
        let requestBody = { content: content, companyId: companyId };
        var apiResponse = await this.commandHttp.post<QuestionnairesImportRecipientsResponse>(url, requestBody);
        return  apiResponse;
    }

    public async downloadTemplate(): Promise<Blob>{
       
        let url = `${this.apiUrls.questionnairesUrl}/download-template`;
        let apiResult = await this.http.get(url, {responseType: 'blob'}).toPromise();

        return apiResult;
    }
}