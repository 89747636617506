import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Guid } from "guid-typescript";
import { LeaderInFocusCommentApiService } from "src/app/api.services/leaderInFocus.comment.api.service";
import { LeaderInFocusCommentsGetResponseItem } from "src/app/api.services/models/leaderInFocusCommentsGetResponseItem";
import { AuthenticationLeaderInFocusService } from "src/app/authorization/authenticationLeaderInFocus.service";
import { DeleteCommentDialog } from "./delete.comment.dialog";


@Component({
  selector: 'comment',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  public questionnaireId: string | null;
  public leaderInFocusId: string | null;
  public comments: LeaderInFocusCommentsGetResponseItem[] = [];
  public leaderInFocusComments: LeaderInFocusComments[] = [];
  public commentsColumns: string[] = [
    'message',
    'showMore',
    'date',
    'actions'];
  public commentsDataSource = new MatTableDataSource<LeaderInFocusCommentsGetResponseItem>(this.leaderInFocusComments);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private leaderInFocusCommentApiService: LeaderInFocusCommentApiService,
    private deleteCommentDialog: MatDialog,
    private route: ActivatedRoute,
    private authenticationLeaderInFocus: AuthenticationLeaderInFocusService
  ) { }

  async ngOnInit(): Promise<void> {
    this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
    this.leaderInFocusId = this.route.snapshot.paramMap.get("leaderInFocusId");
    if (this.leaderInFocusId == null) {
      var auth = this.authenticationLeaderInFocus.getAuth();
      this.leaderInFocusId = auth.userId;
    }
    await this.getComments();
  }

  public addCommentAction() {
    var comment = new LeaderInFocusComments();
    comment.message = "";
    comment.isAdded = true;
    comment.isEdit = true;
    comment.date = new Date(Date.now());
    comment.readMore = false;
    comment.readLess = false;
    comment.id = Guid.create().toString();
    this.leaderInFocusComments.unshift(comment);
    this.commentsDataSource = new MatTableDataSource<LeaderInFocusComments>(this.leaderInFocusComments);
    this.commentsDataSource.paginator = this.paginator;
  }

  public onEdit(comment: any): void {
    var leaderInFocusCommentIndex = this.leaderInFocusComments.findIndex(x => x.id == comment.id);
    if (leaderInFocusCommentIndex != -1) {
      this.leaderInFocusComments[leaderInFocusCommentIndex].isEdit = true;
    }
  }

  public onSave(comment: any): void {
    if (comment.isAdded) {
      this.addComment(comment.message);
    }
    else {
      this.editComment(comment.id, comment.message);
    }
  }

  public onRemove(comment: any): void {
    if (comment.isAdded) {
      this.leaderInFocusComments.shift();
      this.commentsDataSource = new MatTableDataSource<LeaderInFocusComments>(this.leaderInFocusComments);
      this.commentsDataSource.paginator = this.paginator;
    }
    else if (comment.isEdit && !comment.isAdded) {
      var leaderInFocusCommentIndex = this.leaderInFocusComments.findIndex(x => x.id == comment.id);
      if (leaderInFocusCommentIndex != -1) {
        this.leaderInFocusComments[leaderInFocusCommentIndex].isEdit = false;
      }
    }
    else if (!comment.isAdded && !comment.isEdit) {
      const dialogRef = this.deleteCommentDialog.open(DeleteCommentDialog, {
        width: '600px',
        data: { id: comment.id }
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          await this.deleteComment(result.id);
        }
      });
    }
  }

  public showMore(comment) {
    comment.readLess = true;
    comment.readMore = false;
  }

  public showLess(comment) {
    comment.readLess = false;
    comment.readMore = true;
  }

  private async getComments() {
    this.leaderInFocusComments = [];
    this.comments = await this.leaderInFocusCommentApiService.getComments(this.questionnaireId);

    this.comments.forEach(element => {
      var comment = new LeaderInFocusComments();
      comment.date = element.date;
      comment.id = element.id;
      comment.message = element.message;
      comment.readMore = element.message.length > 110;
      comment.readLess = false;
      comment.isEdit = false;
      comment.isAdded = false;
      this.leaderInFocusComments.push(comment);
    });

    this.commentsDataSource = new MatTableDataSource<LeaderInFocusComments>(this.leaderInFocusComments);
    this.commentsDataSource.paginator = this.paginator;
  }

  private async editComment(id: string, message: string) {
    await this.leaderInFocusCommentApiService.editComment(id, message);
    await this.getComments();
  }

  private async addComment(message: string) {
    await this.leaderInFocusCommentApiService.addComment(this.leaderInFocusId, this.questionnaireId, message);
    await this.getComments();
  }

  private async deleteComment(id: string) {
    await this.leaderInFocusCommentApiService.deleteComment(id);
    await this.getComments();
  }


}


class LeaderInFocusComments {
  id?: string;
  message?: string;
  readMore: boolean;
  readLess: boolean;
  date?: Date;
  isEdit: boolean;
  isAdded: boolean;
}