import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { LeaderInFocusInsightsApiService } from "src/app/api.services/leaderInFocuse.insights.api.service";
import { IndexInfoDialog } from "./indexInfo.dialog";


@Component({
    selector: 'realityCheck-index',
    templateUrl: './realityCheck-index.component.html',
    styleUrls: ['./realityCheck-index.component.scss']
})
export class RealityCheckIndexComponent implements OnInit {
  
    public questionnaireId :string;
    @Input() actionLevel = "";
    public realityCheckIndex: number;
    constructor(
        private route: ActivatedRoute,
        private infoDialog: MatDialog,
        private leaderInFocusInsightsApiService: LeaderInFocusInsightsApiService
    ) { }


    ngOnInit(): void {
        this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
        this.getRealityCheckIndex();
    }

    public openInfoDialog(): void {
        const elemRect = (event.target as HTMLElement).getBoundingClientRect()
        const dialogRef = this.infoDialog.open(IndexInfoDialog, {
            width: '300px',
            height: '300px',
            autoFocus: false,
            position: {
                top: elemRect.bottom + 10 + "px",
                left: elemRect.right - 200 + "px"
            }
        });
    }

    private async getRealityCheckIndex(){
        this.realityCheckIndex = await this.leaderInFocusInsightsApiService.getIndex(this.questionnaireId);
    }
}