import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { LeaderInFocusInsightsApiService } from "src/app/api.services/leaderInFocuse.insights.api.service";
import { LeaderInFocusInsightsGetAveragePerAreaResponseGroup } from "src/app/api.services/models/leaderInFocusInsightsGetAveragePerAreaResponseGroup";
import { AreaInfoDialog } from "./areaInfo.dialog";

@Component({
    selector: 'average-per-area',
    templateUrl: './average-per-area.component.html',
    styleUrls: ['./average-per-area.component.scss']
})
export class AveragePerAreaComponent implements OnInit {

    public data: LeaderInFocusInsightsGetAveragePerAreaResponseGroup[];
    public questionnaireId: string;
    constructor(
        private route: ActivatedRoute,
        private infoDialog: MatDialog,
        private leaderInFocusInsightsApiService: LeaderInFocusInsightsApiService,
    ) { }

    ngOnInit(): void {
        this.questionnaireId = this.route.snapshot.paramMap.get("questionnaireId");
        this.getAveragePerAreaData();
    }

    public openInfoDialog(): void {
        const elemRect = (event.target as HTMLElement).getBoundingClientRect()
        const dialogRef = this.infoDialog.open(AreaInfoDialog, {
            width: '300px',
            height: '300px',
            autoFocus: false,
            position: {
                top: elemRect.bottom + 20 + "px",
                left: elemRect.right - 250 + "px"
            }
        });
    }

    private async getAveragePerAreaData() {
        this.data = await this.leaderInFocusInsightsApiService.getAveragePerArea(this.questionnaireId);
    }
}