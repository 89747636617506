import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  ReportGenerateReportDataResponse,
  ReportGenerateReportDataResultsByQuestionWithArea,
  ReportGenerateReportDataRecipientGroupData,
  ReportGenerateReportDataThreeHighestAndLowestAnswersQuestions
} from "src/app/api.services/models/models";
import { FormGroup } from "@angular/forms";
import { AuthenticationLeaderInFocusService } from "src/app/authorization/authenticationLeaderInFocus.service";

@Component({
  selector: 'questionnaire-report',
  templateUrl: './questionnaire-report.component.html',
  styleUrls: ['./questionnaire-report.component.scss']
})
export class QuestionnaireReportComponent implements OnInit {

  public questionnaireTitle: string = '';
  public leaderInFocus: string = '';
  public firstName: string = '';
  public numberOfRecipients: number = 0;
  public recipientGroupData: ReportGenerateReportDataRecipientGroupData[];
  public resultsByQuestionWithArea: ReportGenerateReportDataResultsByQuestionWithArea[];
  public maxByQuestionWithAreaValue: number;
  public threeHighestAndLowestAnswers: ReportGenerateReportDataThreeHighestAndLowestAnswersQuestions[];

  public resultByQuestionCount: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  public form: FormGroup;
  public chartData: string;
  public questionnaireId: string;
  public docDefinition: any;

  public view: any[] = [900, 200];
  public view2: any[] = [900, 200];
  public view3: any[] = [900, 200];


  public numView1: number = 3;
  public numView2: number = 3;
  public numView3: number = 3;
  public colorScheme = {
    domain: ['#C9D0D3']
  };
  // options
  public showXAxis: boolean = false;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = false;
  public showXAxisLabel: boolean = false;
  public showYAxisLabel: boolean = false;
  public showDataLabel: boolean = true;
  public isLeaderInFocus: boolean = false;

  constructor(private route: ActivatedRoute, private authenticationLeaderInFocusService: AuthenticationLeaderInFocusService) { }

  public async ngOnInit() {
    var leaderInFocus = this.authenticationLeaderInFocusService.getAuth();
    this.isLeaderInFocus = leaderInFocus !=null && leaderInFocus.role == 'LeaderInFocus' ? true : false; 
    var dataReport = this.route.snapshot.data.report as ReportGenerateReportDataResponse;

    this.questionnaireTitle = dataReport.title;
    this.leaderInFocus = dataReport.leaderInFocus;
    this.firstName = this.leaderInFocus.split(' ')[0];
    this.numberOfRecipients = dataReport.totalNumberOfRecipients;
    this.recipientGroupData = dataReport.recipientGroups;
    this.resultsByQuestionWithArea = dataReport.resultsByQuestionWithArea;
    this.maxByQuestionWithAreaValue = dataReport.maxByQuestionWithAreaValue;
    this.threeHighestAndLowestAnswers = dataReport.reasaltForThreeHighestAndLowestAnswersByRecipientGroup;

    if (this.resultsByQuestionWithArea.length > 0) {
      var lengthOfRecipients = this.resultsByQuestionWithArea[0].resultByQuestion[0].results.length;
      var numberOfViewAndCountNumberForThirdView = this.numberOfViewAndCountNumber(lengthOfRecipients);
      this.view3 = [900, numberOfViewAndCountNumberForThirdView[0]];
      this.numView3 = numberOfViewAndCountNumberForThirdView[1];
    }
  }

  public downloadPDF() {

    let bodyString: string = "";

    bodyString += this.getReportTitle();

    bodyString += this.getGroup(1);

    bodyString += this.getGroup(2);

    // bodyString += this.getGroup(3);

    // bodyString += this.getGroup(4);

  //  bodyString += this.getFreeBoxPage();;
    var test = document.getElementById("print-leaderInFocus");
    test.innerHTML = `Leader in focus:  <b>${this.leaderInFocus}</b>`;
    var test = document.getElementById("print-welcome");
    test.innerHTML = `Welcome  ${this.firstName}!`;
    var test = document.getElementById("print-summary");
    var recipientGroupeText = "";
    this.recipientGroupData.forEach(x => {
      recipientGroupeText += `<b> ${x.title}</b> : ${x.numberOfReports} people<br/>`
    });
    test.innerHTML = `In this <b> RealityCheck Report</b>, a total <br/> number of <b> ${this.numberOfRecipients} people</b> has responded in <br/> the following recipient groups: <br/> ${recipientGroupeText}`;

    const printPageBody = document.querySelector("#print-page-body");
    printPageBody.innerHTML = bodyString;
    window.print();
    printPageBody.innerHTML = "";
  }

  private getReportTitle(): string {
    let title: string = `<h5 class="report-page-header-title bold-text">${this.leaderInFocus}</h5>`;

    return title;
  }

  private getGroup(groupNumber: number): string {
    let bodyString: string = "";
    const text = document.querySelectorAll(`[data-print-${groupNumber}]`);
    const group = document.querySelectorAll(`[data-print-group-${groupNumber}]`);

    if (group.length == 0) {
      return bodyString;
    }
    if (groupNumber == 1) {
      bodyString += "<div style='height: 100px;'></div>"
    }
    else {
      bodyString += "<div style='height: 150px;'></div>"
    }

    if (text.length > 0) {
      bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>${text[0].innerHTML}</div>`;
    }


    // if (groupNumber == 1) {
    //   let numberOfElements = 0;
    //   let IsFirst = true;
    //   let number = 0
    //   for (let index = 0; index < group.length; index++) {
    //     if (group[index]) {
    //       bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'><table style="width: 100%">${group[index].innerHTML}</table><hr/></div>`;
    //     }

    //     if (IsFirst) {
    //       if (this.numView1 > 4) {
    //         number = 2
    //       }
    //       else {
    //         number = 1;
    //       }
    //     }
    //     else {
    //       number = 1
    //     }
    //     if (index < group.length - 1 && numberOfElements == this.numView1 - number) {
    //       bodyString += "<div style='page-break-after: always;'></div>";
    //       bodyString += "<div style='height: 150px;'></div>";
    //       numberOfElements = 0;
    //       IsFirst = false;
    //     }
    //     else {
    //       numberOfElements++;
    //     }
    //   }

    // }
    // if (groupNumber == 2) {
    //   let numberOfElements = 0;
    //   for (let index = 0; index < group.length; index++) {
    //     bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'><table style="width: 100%">${group[index].innerHTML}</table><hr/></div>`;

    //     if (index < group.length - 1 && numberOfElements == this.numView2 - 1) {
    //       bodyString += "<div style='page-break-after: always;'></div>";
    //       bodyString += "<div style='height: 150px;'></div>";
    //       numberOfElements = 0;
    //     }
    //     else {
    //       numberOfElements++;
    //     }
    //   }

    // }
    if (groupNumber == 2) {
      for (let i = 0; i < group.length; i++) {
        var countH = 0;
        var table = group[i] as HTMLTableElement;
        countH += table.tHead.offsetHeight;
        if (i > 0) {
          bodyString += "<div style='page-break-after: always;'></div>";
          bodyString += "<div style='height: 150px;'></div>";
          bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>${table.tHead.innerHTML}</div>`;
        }
        else {
          bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>${table.tHead.innerHTML}</div>`;
        }
        let numberOfElements = 0;
        for (let index = 0; index < table.tBodies.length; index++) {
          if (table.tBodies[index].classList.contains('subgroup')) {
            var elements = table.tBodies[index].children;
            var first = true;
            for (let k = 0; k < elements.length; k++) {

              if (numberOfElements > 0 && countH > 1000) {
                bodyString += "<div style='page-break-after: always;'></div>";
                if (k < elements.length - 1) {
                  bodyString += "<div style='height: 180px;'></div>";
                }
                numberOfElements = 0;
                countH = 0;
              }
              if (k > 0) {
                var isElementCheck = elements[k].children[1].children[0].children[0].getAttribute('checked');
                if (first && isElementCheck == "true") {
                  bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>${elements[0].innerHTML}</div>`;
                  countH += (elements[0] as HTMLElement).offsetHeight;
                  first = false;
                }
                if (isElementCheck == "true") {
                  if (k == 1) bodyString += `<hr/>`
                  bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>
                  <table style="width:1000px;">
                    <tbody>
                        <tr>
                        <td style="width:850px;">${elements[k].children[1].children[1].children[0].innerHTML}</td>
                        <td style="width:150px; text-align:right" class='text-color'>${elements[k].children[1].children[2].innerHTML}</td>
                        </tr>
                    </tbody>
                  </table>
                 </div>
                  <hr/>`;
                  countH += (elements[k].children[1].children[1].children[0] as HTMLElement).offsetHeight;
                  countH += (elements[k].children[1].children[2] as HTMLElement).offsetHeight;
                }
              }

              if (numberOfElements > 0) {
                if (countH >= 1000) {
                  bodyString += "<div style='page-break-after: always;'></div>";
                  if (k < elements.length - 1) {
                    bodyString += "<div style='height: 180px;'></div>";
                  }
                  numberOfElements = 0;
                  countH = 0;
                }
              }
              else {
                if (countH >= 1300) {
                  bodyString += "<div style='page-break-after: always;'></div>";
                  if (k < elements.length - 1) {
                    bodyString += "<div style='height: 180px;'></div>";
                  }
                  numberOfElements = 0;
                  countH = 0;
                }
              }

            }
          }
          else {
            bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>${table.tBodies[index].innerHTML}</div>`;
            countH += table.tBodies[index].offsetHeight;
            if (index < table.tBodies.length - 1 && numberOfElements == this.numView3 - 1) {
              bodyString += "<div style='page-break-after: always;'></div>";
              bodyString += "<div style='height: 150px;'></div>";
              numberOfElements = 0;
              countH = 0;
            }
            else {
              numberOfElements++;
            }
          }
        }
      }
    }
    if (groupNumber == 1) {
      var test = group[0] as HTMLElement;
      if (test.offsetHeight <= 850) {
        bodyString += `<div style=' width: 100%; display: inline-block; '>${group[0].innerHTML}</div>`;
      }
      else {
        let numberOfElements = 0;
        const help = document.querySelectorAll(`[data-print-group-help]`);
        var table = help[0] as HTMLTableElement;
        bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>
          <table class="greyGridTable" style="width: 100%">
          <thead style="border-bottom: 1px solid #C9D0D3;
          border-top   : 1px solid #C9D0D3;">
          <tr>
              <th style="padding: 10px 10px;"></th>
              <th style="padding: 10px 10px;">Exceeding Expectations</th>
              <th style="padding: 10px 10px;">Potential</th>
          </tr>
          </thead>
          <tbody>`;
        var start = true;
        for (let index = 0; index < table.tBodies[0].children.length; index++) {

          bodyString += `<tr>${table.tBodies[0].children[index].innerHTML} </tr>`;

          if (index < table.tBodies[0].children.length - 1 && numberOfElements == 3) {
            bodyString += "</tbody></table></div>";
            bodyString += "<div style='page-break-after: always;'></div>";
            bodyString += "<div style='height: 150px;'></div>";
            bodyString += `<div style='page-break-inside: avoid; width: 100%; display: inline-block;'>
              <table style="width: 100%" class="greyGridTable">
              <thead style="border-bottom: 1px solid #C9D0D3;
              border-top   : 1px solid #C9D0D3;">
              <tr>
                  <th style="padding: 10px 10px;"></th>
                  <th style="padding: 10px 10px;">Exceeding Expectations</th>
                  <th style="padding: 10px 10px;">Potential</th>
              </tr>
              </thead>
              <tbody>`;
            numberOfElements = 0;
            start = false;
          }
          else {
            numberOfElements++;
            start = true;
          }

        }
        if (start) {
          bodyString += "</tbody></table></div>";
        }
      }
    }

    if (groupNumber < 2) {
      bodyString += "<div style='page-break-after: always;'></div>";
    }

    return bodyString;
  }

  public boxChange() {
    var element = event.currentTarget as HTMLElement;
    var checked = element.getAttribute('checked');
    if (checked) {
      element.setAttribute('checked', 'false');
    }
    else {
      element.setAttribute('checked', 'true');
    }
  }

  private getFreeBoxPage(): string {
    let bodyString: string = "";

    bodyString += `
      <div style='page-break-after: always;'></div>
      <div  style='height: 147px;'></div>
      <div style='z-index: 1000; 
        height: 1220px; 
        border-radius: 25px;
        border: 2px solid #C9D0D3;'></div>`;

    return bodyString;
  }

  numberOfViewAndCountNumber(numberOfBars) {
    if (numberOfBars == 1) {
      return [50, 8];
    }
    else if (numberOfBars == 2) {
      return [70, 7]
    }
    else if (numberOfBars == 3) {
      return [100, 6]
    }
    else if (numberOfBars >= 4 && numberOfBars <= 6) {
      return [130, 5];
    }
    else if (numberOfBars >= 7 && numberOfBars <= 9) {
      return [170, 4];
    }
    else if (numberOfBars >= 10 && numberOfBars <= 12) {
      return [270, 3];
    }
    else if (numberOfBars >= 13 && numberOfBars <= 15) {
      return [300, 3]
    }
    else if (numberOfBars >= 16) {
      return [500, 2];
    }
  }
}
