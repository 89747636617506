export class ApiResult<T> {
    isSuccess: boolean;
    message: string;
    data: T;

    constructor(init?: Partial<ApiResult<T>>) {
        Object.assign(this, init);
    }

    static error<T>(errorMessage: string) {
        return new ApiResult<T>({ isSuccess: false, message: errorMessage });
    }

    static success<T>(data: T = null, message: string = '') {
        return new ApiResult<T>({ isSuccess: true, message: message, data: data });
    }
}