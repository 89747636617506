import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './authorization/login/login.component';
import { CompaniesComponent } from './companies/companies.component';
import { RecipientGroupsComponent } from './recipient-groups/recipient.groups.component';
import { QuestionnairesListComponent } from './questionnaire/questionnaire-list/questionnaires.list.component';
import { SurveysComponent } from './surveys/surveys.component';
import { SurveysLayoutComponent } from './layouts/surveys-layout/surveys-layout.component';
import { SurveysReslover } from './surveys/surveys.resolver.service';
import { MessageComponent } from './message/message.component';
import { QuestionnaireReportComponent } from './questionnaire/questionnaire-report/questionnaire-report.component';
import { QuestionnaireReportResolver } from './questionnaire/questionnaire-report/questionnaire-report.resolver.service';
import { AuthGuard } from './authorization/guards/auth-guard.service';
import { MessageLayoutComponent } from './layouts/message-layout/message-layout.component';
import { QuestionnaireRecipientsComponent } from './questionnaire-recipients/questionnaire-recipients.component';
import { SetPasswordComponent } from './authorization/set-password/set.password.component';
import { CreateQuestionnaireComponent } from './questionnaire/create-questionnaire/create-questionnaire.component';
import { LeaderInFocusLayoutComponent } from './layouts/leaderInFocus-layout/leaderInFocus-layout.component';
import { LeaderInFocuseGuard } from './authorization/guards/leaderInFocus-guard.service';
import { LeaderInFocuseLoginLayoutComponent } from './layouts/leaderInFocus-login-layout/leaderInFocus-login-layout.component';
import { LeaderInFocuseEmailRequestComponent } from './authorization/leaderInFocusEmailRequest/leaderInFocuse-emailRequest.component';
import { LeaderInFocuseLogInComponent } from './authorization/leaderInFocusLogIn/leaderInFocuse-login.component';
import { LeaderInFocusInsightComponent } from './leaderInFocus-insight/leaderInFocus-insight.component';
import { CommentsAndActionsComponent } from './leaderInFocus/commentsAndActions/commentsAndActions.component';
import { ConsentComponent } from './gdprCompliances/consent/consent.component';
import { PrivacyPolicyComponent } from './gdprCompliances/privacyPolicy/privacyPolicy.component';
import { GeneralConditionsComponent } from './gdprCompliances/generalConditions/generalConditions.component';
import { GDPRGuard } from './shared/guards/gdpr-guard.service';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'login' },
      { path: 'login', component: LoginComponent },
      { path: 'set-password/:userId', component: SetPasswordComponent}
    ],
  },
  {
    path: '',
    component: LeaderInFocuseLoginLayoutComponent,
    children: [
      { path: 'leaderInFocus', component: LeaderInFocuseEmailRequestComponent },
      { path: 'leaderInFocus/:tenantId', component: LeaderInFocuseEmailRequestComponent },
      { path: 'leaderInFocus/:tenantId/login', component: LeaderInFocuseLogInComponent}
    ],
  },
  {
    path: '',
    component: MessageLayoutComponent,
    children: [
      { path: 'message', component: MessageComponent, data :{ message:""} }
    ],
  },
  {
    path: '',
    component: SurveysLayoutComponent,
    children: [
      { path: 'surveys/:token', component: SurveysComponent, resolve: { surveys: SurveysReslover}, canActivate: [GDPRGuard] },
      { path: 'gdpr', component: ConsentComponent },
      { path: 'privacyPolicy', component: PrivacyPolicyComponent },
      { path: 'generalConditions', component: GeneralConditionsComponent },
    ],
  }, {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent,  canActivate: [GDPRGuard] },
      { path: 'questionnaire/add', component: CreateQuestionnaireComponent,  canActivate: [GDPRGuard] },
      { path: 'clients/:companyId/:companyName/questionnaires', component: QuestionnairesListComponent,  canActivate: [GDPRGuard] },
      { path: 'clients', component: CompaniesComponent,  canActivate: [GDPRGuard] },
      { path: 'templates', loadChildren: () => import('./templates/templates.module').then(m => m.TemplateModule),  canActivateChild: [GDPRGuard] },
      { path: 'clients/:companyId/:companyName/recipient-groups', component: RecipientGroupsComponent,  canActivate: [GDPRGuard] },
      { path: 'clients/:companyId/:companyName/questionnaires/:questionnaireId/report', component: QuestionnaireReportComponent, resolve: { report: QuestionnaireReportResolver },  canActivate: [GDPRGuard] },
      { path: 'clients/:companyId/:companyName/questionnaires/:questionnaireId/insight/:leaderInFocusId', component: LeaderInFocusInsightComponent,  canActivate: [GDPRGuard] } ,
      { path: 'clients/:companyId/:companyName/questionnaires/:questionnaireId/insight/:leaderInFocusId/action', component: CommentsAndActionsComponent,  canActivate: [GDPRGuard] } ,
      { path: 'clients/:companyId/:companyName/questionnaires/:questionnaireId/:questionnaireName/recipients', component: QuestionnaireRecipientsComponent , canActivate: [GDPRGuard]},
      { path: 'admin' , loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),  canActivateChild: [GDPRGuard]}
    ],
  },
  {
    path: '',
    component: LeaderInFocusLayoutComponent,
    canActivate: [LeaderInFocuseGuard],
    canActivateChild: [LeaderInFocuseGuard],
    children: [
      { path: 'leaderInFocus/:tenantId', loadChildren: () => import('./leaderInFocus.module').then(m => m.LeaderInFocusModule),  canActivateChild: [GDPRGuard]  }
     
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [SurveysReslover, QuestionnaireReportResolver, AuthGuard, LeaderInFocuseGuard]
})
export class AppRoutingModule { }

