import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './shared/modules/angular-material.module';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './authorization/login/login.component';
import { CompaniesComponent } from './companies/companies.component';
import { AddHeaderInterceptor } from './authorization/header.interceptor';
import { AddCompanyDialog } from './companies/add.company.dialog';
import { EditCompanyDialog } from './companies/edit.company.dialog';
import { RecipientGroupsComponent } from './recipient-groups/recipient.groups.component';
import { TemplateModule } from './templates/templates.module';
import { AddRecipientGroupDialog } from './recipient-groups/add.recipient.group.dialog';
import { EditRecipientGroupDialog } from './recipient-groups/edit.recipient.group.dialog';
import { QuestionnairesListComponent } from './questionnaire/questionnaire-list/questionnaires.list.component';
import { SurveysComponent } from './surveys/surveys.component';
import { SharedModule } from './shared/shared.module';
import { RatingComponent } from './shared/rating/rating.component';
import { SurveysLayoutComponent } from './layouts/surveys-layout/surveys-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { MessageComponent } from './message/message.component';
import { QuestionnaireReportComponent } from './questionnaire/questionnaire-report/questionnaire-report.component';
import { NgxChartsModule, PieChartComponent } from '@swimlane/ngx-charts';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DeleteCompanyDialog } from './companies/delete.company.dialog';
import { DeleteRecipientGroupDialog } from './recipient-groups/delete.recipient.group.dialog';
import { DeleteQuestionnaireDialog } from './questionnaire/questionnaire-list/delete.questionnaire.dialog';
import { DeleteTemplateDialog } from './templates/template-list/delete.template.dialog';
import { MessageLayoutComponent } from './layouts/message-layout/message-layout.component';
import { QuestionnaireRecipientsComponent } from './questionnaire-recipients/questionnaire-recipients.component';
import { ResendEmailDialog } from './questionnaire-recipients/resend-email.dialog';
import { ResendEmailAllDialog } from './questionnaire-recipients/resend-email-all.dialog';
import { DeleteQuestionnareRecipientDialog } from './questionnaire-recipients/delete.recipients.dialog';
import { EditQuestionnareRecipientDialog } from './questionnaire-recipients/edit.recipient.dialog';
import { AddQuestionnareRecipientDialog } from './questionnaire-recipients/add.recipient.dialog';
import { AdminModule } from './admin/admin.module';
import { SetPasswordComponent } from './authorization/set-password/set.password.component';
import { ConfirmationDialog } from './surveys/confirmation.dialog';
import { InformationalDialog } from './layouts/app-layout/informational.dialog';
import { CreateQuestionnaireComponent } from './questionnaire/create-questionnaire/create-questionnaire.component';
import { StepOneComponent } from './questionnaire/create-questionnaire/step-one/step-one.component';
import { StepTwoComponent } from './questionnaire/create-questionnaire/step-two/step-two.component';
import { StepThreeComponent } from './questionnaire/create-questionnaire/step-three/step-three.component';
import { StepFourComponent } from './questionnaire/create-questionnaire/step-four/step-four.component';
import { StepFiveComponent } from './questionnaire/create-questionnaire/step-five/step-five.component';
import { LeaderInFocuseLoginLayoutComponent } from './layouts/leaderInFocus-login-layout/leaderInFocus-login-layout.component';
import { LeaderInFocuseEmailRequestComponent } from './authorization/leaderInFocusEmailRequest/leaderInFocuse-emailRequest.component';
import { LeaderInFocuseLogInComponent } from './authorization/leaderInFocusLogIn/leaderInFocuse-login.component';
import { AutoTabDirective } from './shared/directive/autoTab.directive';
import { KeyUpDirective } from './shared/directive/keyUp.directive';
import { LeaderInFocusModule } from './leaderInFocus.module';
import { LeaderInFocusLayoutComponent } from './layouts/leaderInFocus-layout/leaderInFocus-layout.component';
import { DeletePersonalNotesDialog } from './leaderInFocus/personal-notes/delete.personal-notes.dialog';
import { DeleteCommentDialog } from './leaderInFocus/comments/delete.comment.dialog';
import { DeleteActionDialog } from './leaderInFocus/actions/delete.action.dialog';
import { IndexInfoDialog } from './leaderInFocus-insight/realityCheck-index/indexInfo.dialog';
import { IndexPerGroupInfoDialog } from './leaderInFocus-insight/realityCheck-index-per-group/indexPerGroupInfo.dialog';
import { ActionInfoDialog } from './leaderInFocus-insight/leaderInFocus-insight-actions/actionInfo.dialog';
import { StepperComponent } from './shared/stepper/stepper.component';
import { AreaInfoDialog } from './leaderInFocus-insight/average-per-area/areaInfo.dialog';
import { CompleteCheckDialog } from './leaderInFocus/actions/complete-check.dialog';
import { NgxPieChartZeroMarginDirective } from './shared/directive/pie-chart.directive';
import { PersonalNotesInfoDialog } from './leaderInFocus/personal-notes/infoPersonalNotes.dialog';
import { ConsentComponent } from './gdprCompliances/consent/consent.component';
import { GeneralConditionsComponent } from './gdprCompliances/generalConditions/generalConditions.component';
import { PrivacyPolicyComponent } from './gdprCompliances/privacyPolicy/privacyPolicy.component';

@NgModule({
  declarations: [
    AppComponent,
    LeaderInFocusLayoutComponent,
    LeaderInFocuseLoginLayoutComponent,
    LeaderInFocuseEmailRequestComponent,
    LeaderInFocuseLogInComponent,
    PublicLayoutComponent,
    AppLayoutComponent,
    SurveysLayoutComponent,
    MessageLayoutComponent,
    DashboardComponent,
    CreateQuestionnaireComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    LoginComponent,
    CompaniesComponent,
    AddCompanyDialog,
    EditCompanyDialog,
    RecipientGroupsComponent,
    AddRecipientGroupDialog,
    EditRecipientGroupDialog,
    QuestionnairesListComponent,
    RatingComponent,
    SurveysComponent,
    MessageComponent,
    QuestionnaireReportComponent,
    DeleteCompanyDialog,
    DeleteRecipientGroupDialog,
    DeleteQuestionnaireDialog,
    IndexInfoDialog,
    IndexPerGroupInfoDialog,
    DeleteCommentDialog,
    DeleteActionDialog,
    DeletePersonalNotesDialog,
    DeleteTemplateDialog,
    QuestionnaireRecipientsComponent,
    ResendEmailDialog,
    ResendEmailAllDialog,
    DeleteQuestionnareRecipientDialog,
    EditQuestionnareRecipientDialog,
    AddQuestionnareRecipientDialog,
    SetPasswordComponent,
    ConfirmationDialog,
    InformationalDialog,
    AutoTabDirective,
    KeyUpDirective,
    ActionInfoDialog,
    AreaInfoDialog,
    CompleteCheckDialog,
    PersonalNotesInfoDialog,
    ConsentComponent,
    GeneralConditionsComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    AngularMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TemplateModule,
    SharedModule,
    NgxChartsModule,
    AdminModule,
    LeaderInFocusModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true, },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
