import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AddRecipientGroupDialog } from './add.recipient.group.dialog';
import { EditRecipientGroupDialog } from './edit.recipient.group.dialog';
import { RecipientGroupsApiService } from '../api.services/recipient.groups.api.service';
import { RecipientGroupsGetByCompanyIdResponseItem } from '../api.services/models/models';
import { DeleteRecipientGroupDialog } from './delete.recipient.group.dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-recipient-groups',
    styleUrls: ['recipient.groups.component.scss'],
    templateUrl: './recipient.groups.component.html'
})

export class RecipientGroupsComponent implements OnInit {
    companyId: string = "";
    companyName: string = "";
    recipientGroups: RecipientGroupsGetByCompanyIdResponseItem[] = [];
    recipientGroupDisplayedColumns: string[] = [
        'name',
        'order',
        'isLeader',
        'isBoss',
        'minRecipients',
        'actions'];
    recipientGroupDataSource = new MatTableDataSource<RecipientGroupsGetByCompanyIdResponseItem>(this.recipientGroups);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private recipientGroupsApiService: RecipientGroupsApiService,
        private route: ActivatedRoute,
        private addRecipientGroupDialog: MatDialog,
        private editRecipientGroupDialog: MatDialog,
        private deleteRecipientGroupDialog: MatDialog,
        private toastrService: ToastrService
    ) { }

    ngOnInit() {
        this.companyId = this.route.snapshot.paramMap.get('companyId');
        this.companyName = this.route.snapshot.paramMap.get('companyName');
        this.getRecipientGroups();
    }

    openDialogAddRecipientGroups(): void {
        const dialogRef = this.addRecipientGroupDialog.open(AddRecipientGroupDialog, {
            width: '600px',
            data: { name: '', order: 0, isLeader: false, isBoss: false,  minRecipients: 0}
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var recipientGroup = this.recipientGroups.find(x => x.isLeader)
                if(result.isLeader && recipientGroup != null){
                    this.toastrService.info("Only one leader in focus at the time");
                }
                if(result.isLeader && result.minRecipients > 1){
                    this.toastrService.info("Min Recipients for leader in focus must be one.");
                }
                else{
                await this.createRecipientGroups(result.name, result.order, result.isLeader, result.isBoss, result.minRecipients);
                }
            }
        });
    }

    openDialogEditRecipientGroups(recipientGroup: any): void {
        const dialogRef = this.editRecipientGroupDialog.open(EditRecipientGroupDialog, {
            width: '600px',
            data: { id: recipientGroup.id, name: recipientGroup.name, order: recipientGroup.order, isLeader: recipientGroup.isLeader, isBoss: recipientGroup.isBoss , minRecipients: recipientGroup.minRecipients }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                var recipientGroup = this.recipientGroups.find(x => x.isLeader)
                if(result.isLeader && recipientGroup?.id != result.id){
                    this.toastrService.info("Only one leader in focus at the time");
                }
                if(result.isLeader && result.minRecipients > 1){
                    this.toastrService.info("Min Recipients for leader in focus must be one.");
                }
                else{
                    await this.editRecipientGroups(result.id, result.name, result.order, result.isLeader, result.isBoss, result.minRecipients);
                }
              
            }
        });
    }

    openDialogDeleteRecipientGroups(recipientGroup: any): void {
        const dialogRef = this.deleteRecipientGroupDialog.open(DeleteRecipientGroupDialog, {
            width: '600px',
            data: { id: recipientGroup.id, name: recipientGroup.name },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.deleteRecipientGroups(result.id);
            }
        });
    }

    private async getRecipientGroups() {
        this.recipientGroups = await this.recipientGroupsApiService.getRecipientGroupsByCompanyId(this.companyId);
        this.recipientGroupDataSource = new MatTableDataSource<RecipientGroupsGetByCompanyIdResponseItem>(this.recipientGroups);
        this.recipientGroupDataSource.paginator = this.paginator;
    }

    private async createRecipientGroups(recipientGroupName: string, order: number, isLeader: boolean,  isBoss: boolean, minRecipients: number) {
        await this.recipientGroupsApiService.createRecipientGroup(this.companyId, recipientGroupName, order, isLeader, isBoss, minRecipients);
        await  this.getRecipientGroups();
    }

    private async editRecipientGroups(recipientGroupId: string, recipientGroupName: string, order: number, isLeader: boolean,  isBoss: boolean, minRecipients: number) {
        await this.recipientGroupsApiService.editRecipientGroup(recipientGroupId, recipientGroupName, order, this.companyId, isLeader,isBoss, minRecipients);
        await  this.getRecipientGroups();
    }

    private async deleteRecipientGroups(recipientGroupId: string) {
        await this.recipientGroupsApiService.deleteRecipientGroup(recipientGroupId);
        await  this.getRecipientGroups();
    }
}
