import { Component, OnInit, ViewChild } from '@angular/core';
import { TemplatesApiService } from 'src/app/api.services/templates.api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TemplatesGetAllResponseItem } from 'src/app/api.services/models/templatesGetAllResponseItem';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTemplateDialog } from './delete.template.dialog';

@Component({
    selector: 'app-template',
    templateUrl: './template-list.component.html'
})

export class TemplateListComponent implements OnInit {
    public isDataInitialized: boolean = false;
    public templates: TemplatesGetAllResponseItem[] =[];
    public displayedColumns: string[] = [
        'title',
        'description',
        // 'numberOfQuestions',
        'actions'];
    constructor(
        private templatesApiService: TemplatesApiService,
        private deleteTemplateDialog: MatDialog) { }
    public dataSource = new MatTableDataSource<TemplatesGetAllResponseItem>(this.templates);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    public ngOnInit() {
        this.getTemplates();
        this.isDataInitialized = true;
    }

    openDialogDeleteTemplate(template: any): void {
        const dialogRef = this.deleteTemplateDialog.open(DeleteTemplateDialog, {
            width: '600px',
            data: { id: template.id, name: template.title },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.deleteTemplate(result.id);
            }
        });
    }

    private async getTemplates() {
        this.templates = await this.templatesApiService.getTemplates();
        this.dataSource = new MatTableDataSource<TemplatesGetAllResponseItem>(this.templates);
        this.dataSource.paginator = this.paginator;
    }

    private async deleteTemplate(id: string) {
        await this.templatesApiService.deleteTemplate(id);
        await this.getTemplates();;
    }
}
