import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LeaderInFocusLoginResponse } from '../api.services/models/leaderInFocusLoginResponse';

const jwtHelper = new JwtHelperService();
@Injectable({
    providedIn: 'root'
})
export class AuthenticationLeaderInFocusService {
    authKey: string = 'access_token_leaderInFocus';

    constructor(
        private http: HttpClient,
    ) { }

    setAuth(auth: LeaderInFocusLoginResponse) {
        localStorage.setItem(this.authKey, JSON.stringify(auth));
    }

    getAuth(): LeaderInFocusLoginResponse | null {
        var item = localStorage.getItem(this.authKey);
        return item != undefined && item != "undefined" && item != null
            ? JSON.parse(item)
            : null
    }

    isLoggedIn(): boolean {
        return localStorage.getItem(this.authKey) != null;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(this.authKey);
    }

    removeAccessToken(): void {
        let auth: LeaderInFocusLoginResponse = this.getAuth();
        auth.token = "";
        this.setAuth(auth);
    }

    getAccessToken() {
        let gssToken = this.getAuth();
        return gssToken ? gssToken.token : null;
    }

    getRole() {
        var token = this.getAuth();
        if (token) {
            return token.role;
        }
    }

    getTenant() {
        var token = this.getAuth();
        if (token) {
            return token.tenantId;
        }
    }
}