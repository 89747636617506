import { HttpClient, HttpParams } from "@angular/common/http";
import { CommandHttpService } from "./command-http.service";
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { map } from "rxjs/internal/operators/map";
import { catchError } from "rxjs/internal/operators/catchError";
import { 
    LeaderInFocusEmailRequestRequest, 
    LeaderInFocusEmailRequestResponse, 
    LeaderInFocusLoginRequest, 
    LeaderInFocusLoginResponse, 
    UsersLoginRequest, 
    UsersLoginResponse, 
    UsersSetPasswordRequest} from "./models/models";

import { ApiUrls } from "./api-urls";
import { ApiResult } from "../shared/models/api-result";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationApiService{
    constructor(
        private http: HttpClient,
        private commandHttp: CommandHttpService,
        private apiUrls: ApiUrls
    ) { }

    public validateAccessToken(): Observable<boolean> {
        // Validate the token by pinging a secured Endpoint
        // and transforming the result into a boolean
        var url = `${this.apiUrls.authorizationUrl}/validate`;
        return this.http.get<void>(url).pipe(
            map(res => true),
            catchError((err, caught) => of(false))
        );
    }

    public async login(email: string, password: string): Promise<ApiResult<UsersLoginResponse>>{
        let url = `${this.apiUrls.authorizationUrl}/login`;
        let requestBody: UsersLoginRequest = { email: email, password: password };
        let apiResult: ApiResult<UsersLoginResponse> = await this.commandHttp.post<UsersLoginResponse>(url, requestBody);

        return apiResult; 
    }

    public async emailRequestLeaderInFocus(email: string, tenantId: string): Promise<ApiResult<LeaderInFocusEmailRequestResponse>>{
        let url = `${this.apiUrls.authorizationUrl}/email-request-leader-in-focus`;
        let requestBody: LeaderInFocusEmailRequestRequest = { email: email, tenantId: tenantId };
        let apiResult: ApiResult<LeaderInFocusEmailRequestResponse> = await this.commandHttp.post<LeaderInFocusEmailRequestResponse>(url, requestBody);

        return apiResult; 
    }

    public async loginLeaderInFocus(email: string, tenantId: string, password: string): Promise<ApiResult<LeaderInFocusLoginResponse>>{
        let url = `${this.apiUrls.authorizationUrl}/login-leader-in-focus`;
        let requestBody: LeaderInFocusLoginRequest = { email: email, tenantId: tenantId, password: password};
        let apiResult: ApiResult<LeaderInFocusLoginResponse> = await this.commandHttp.post<LeaderInFocusLoginResponse>(url, requestBody);

        return apiResult; 
    }

    public async setPassword(request: UsersSetPasswordRequest): Promise<ApiResult<void>> {
        let url = `${this.apiUrls.authorizationUrl}/set-password`;
        return await this.commandHttp.post(url, request);
    }

    public async setPasswordRequest(userId: string): Promise<ApiResult<void>> {
        let url = `${this.apiUrls.authorizationUrl}/set-password-request`;
        let request = {
            userId: userId
        }
        return await this.commandHttp.post(url, request);
    }

}