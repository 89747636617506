import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class RatingComponent implements OnInit {

    @Input() private rating: number | null = null;
    @Input() private ratingCount: number = 10;
    @Output() private ratingUpdated = new EventEmitter();
  
    private snackBarDuration: number = 2000;
    public ratingArr = [];
  
    constructor(private snackBar: MatSnackBar) {
    }
  
  
    public ngOnInit() {
      for (let index = 0; index < this.ratingCount; index++) {
        this.ratingArr.push(index);
      }
    }
    
    public onClick(rating:number) {
      if(this.rating == rating)
      {
        this.ratingUpdated.emit(null);
        return false;
      }
      else{
      this.ratingUpdated.emit(rating);
      return false;
      }
    }
  
    public showIcon(index:number) {
      if (this.rating >= index + 1) {
        return 'brightness_1';
      } else if(this.rating == null){
        return 'radio_button_unchecked';
      }
      else{
        return 'radio_button_unchecked';
      }
    }
  
  }
  