import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TemplatesApiService } from 'src/app/api.services/templates.api.service';
import { Router } from '@angular/router';
import { TemplatesCreateRequest } from 'src/app/api.services/models/models';
import { ToastrService } from 'ngx-toastr';
import { QuestionTypeEnum } from 'src/app/shared/enums/questionType';

@Component({
    selector: 'app-create-template',
    templateUrl: './create-tempate.component.html'
})

export class CreateTempateComponent implements OnInit {
    public isDataInitialized: boolean = false;
    public templateName: string;
    public form: FormGroup;
    public formArray: FormArray;
    public questionTypes: string[];
    public rating: boolean = false;
    public area: number;
    public question: number;
    protected isApiRequestInProgress: boolean = false;
    public step: number = 0;

    constructor(private formBuilder: FormBuilder,
        private templatesApiService: TemplatesApiService,
        private router: Router,
        public toster: ToastrService
    ) { }


    public ngOnInit() {
        this.questionTypes = ['String', 'Bool', 'Rating'];
        this.form = this.formBuilder.group({
            title: [, Validators.required],
            description: [],
            areas: new FormArray([]),
            ratingValue: [10, Validators.required]
        })
        this.isDataInitialized = true;
    }

    addArea() {
        let control = <FormArray>this.form.controls.areas;
        control.push(this.initArea());
    }

    onRemoveArea(index) {
        let control = <FormArray>this.form.controls.areas;
        control.removeAt(index)
    }

    addNewQuestion(control) {
        control.push(this.initQuestion());
    }

    onRemoveQuestion(control, index) {
        control.removeAt(index)
    }

    public initArea() {
        return this.formBuilder.group({
            title: [, Validators.required],
            isInNegativeConotation: [false],
            questions: new FormArray([
                this.initQuestion()
            ])
        })
    }

    public initQuestion() {
        return this.formBuilder.group({
            title: [, Validators.required],
            description: [],
            questionType: [, Validators.required],
            ratingValue: [],
            israting: [false]
        });
    }

    public panelExpansion(index) {
        this.step = index;
    }

    public async onSubmit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        var template = this.form.value as TemplatesCreateRequest;
        for (let i = 0; i < template.areas.length; i++) {
            template.areas[i].orderNumber = i + 1;
            for (let j = 0; j < template.areas[i].questions.length; j++) {
                template.areas[i].questions[j].orderNumber = j + 1;
                template.areas[i].questions[j].ratingValue = this.form.get('ratingValue').value;
            }
        }
        if (template.areas.length == 0) {
            this.toster.error("Please add Area.");
            return;
        }
        this.isApiRequestInProgress = true;
        var respons = await this.templatesApiService.createTemplates(template);
        this.isApiRequestInProgress = false;

        if (!respons.isSuccess) {
            this.toster.error(respons.message);
            return;
        }

        this.toster.success("Successfully saved.");
        this.router.navigate([`/templates`]);
    }

}
