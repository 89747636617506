import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'questionTypePipe' })
export class QuestionTypePipe implements PipeTransform {
    transform(value: string): string {
        if (value == "String") { return "Text"; }
        else if (value == "Bool") { return "Yes/No"; }
        else return value;
    }

}